:root {
  --theme-green: #6e9a95;
  --dark-green: #40948a;
  --dark-theme: #1c4451;
  --breaker-bay: #53827c;
  --sinbad: #a9d1c5;
  --dim-gray: #606060;
  --green-border: #bdcfcd;
  --gondola: #333333;
  --fiord: #556271;
  --medium-wood: #a97a5a;
  --medium-wood-one: #a58876;
  --ocean-blue: #00b7bd;
  --purple-bright: #582c83;
  --light-brown: #c6aa76;
  --green-light: #8f993e;
  --orange-light: #e18900;
  --slate-gray: #98a4ae;
  --glacier: #85b1bb;
  --rose: #d3a899;
  --fern: #b07060;
  --tallow: #ac9f75;
  --chatelle: #aaa3af;
  --fire-brick: #a0361d;
  --bismark: #42687b;
  --border-color: #d9d9d9;
  --suva-gray: #888888;
  --see-icon: #e3efee;
  --dark-500: #333333;
  --atmosphere-subbrand: #b07c56;
  --byatmosphere-subbrand: #84b2c1;
  --byatmosphere-subbrand-nw: #549dae;
  --atmosphereexp-subbrand: #87789b;
  --atmospheresign-subbrand: #b8807a;
  --atmospherenature-subbrand: #638258;
  --atmospherehrtg-subbrand: #c6a158;
  --atmosphereurbn-subbrand: #546c7d;
}
@font-face {
  font-family: "Georgia-Regular";
  src: url("../fonts/georgia-webfont.woff2") format("woff2"),
    url("../fonts/georgia-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Georgia-Bold";
  src: url("../fonts/georgiab-webfont.woff2") format("woff2"),
    url("../fonts/georgiab-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
::selection {
  background-color: var(--theme-green);
  color: var(--bs-white);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
h1,
.h1 {
  font-size: 46px;
}
h2,
.h2 {
  font-size: 40px;
}
h3,
.h3 {
  font-size: 32px;
}
h6,
.h6 {
  font-size: 18px;
}
p {
  color: var(--fiord);
  line-height: 2.1;
}
img {
  max-width: 100%;
}
@mixin transition($x...) {
  -webkit-transition: $x;
  -moz-transition: $x;
  -ms-transition: $x;
  -o-transition: $x;
  transition: $x;
}
.smooth {
  @include transition(all 400ms ease-in-out 0s);
}
.georgia {
  font-family: "Georgia-Regular";
}
.georgia-bold {
  font-family: "Georgia-Bold";
}
form {
  .form-control {
    border-color: var(--border-color);
    border-radius: 4px;
  }
}
.btn {
  @extend .smooth;
  &.theme-btn {
    background-color: var(--theme-green);
    padding: 13.5px 55px;
    text-align: center;
    font-size: 14px;
    &.border-theme {
      border-color: var(--theme-green);
    }
    &:hover,
    &:focus {
      background-color: var(--dark-500);
      border-color: var(--dark-500);
      color: var(--bs-white) !important;
    }
  }
  &.theme-btn-outline {
    color: var(--theme-green);
    font-size: 20px;
    padding: 19px 42px;
    &:hover,
    &:focus {
      background-color: var(--breaker-bay);
      border-color: var(--breaker-bay);
      color: var(--bs-white) !important;
    }
  }
}
.section-padding {
  padding: 100px 0;
}
header {
  position: fixed;
  @extend .smooth;
  padding: 22px 50px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 182px;
    background: rgb(51, 51, 51);
    background: linear-gradient(
      0deg,
      rgba(51, 51, 51, 0) 0%,
      rgb(51, 51, 51) 100%
    );
  }
  .header-wrap {
    z-index: 1;
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 9px;
      width: calc(50% - 145px);
      height: 1px;
      background-color: rgba(255, 255, 255, 0.5);
      @extend .smooth;
    }
    &::after {
      left: initial;
      right: 0;
    }
  }
  .atmosphere-centered-logo {
    width: 242px;
    img.logo-fixed {
      display: none;
      top: -29px;
    }
    .logo-sticky {
      top: -47px;
    }
  }
  .menu-btn {
    width: 30px;
    padding: 0;
    top: 25px;
    img {
      width: 30px;
    }
  }
  .atmosphere-living-nav {
    .navbar-nav {
      .nav-ul {
        width: calc(50% - 115px);
        &.end-nav-list {
          padding-left: 70px;
        }
        &.start-nav-list {
          justify-content: flex-end;
          padding-right: 70px;
        }
      }
      .nav-item {
        margin: 0 5px;
        .nav-link {
          padding: 18px 0;
          font-size: 14px;
          position: relative;
          @extend .smooth;
          &::before {
            content: "";
            height: 3px;
            width: 0;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            bottom: 0;
            background-color: var(--bs-white);
            @extend .smooth;
          }
          &.active {
            &::before {
              width: 100%;
            }
          }
          &:hover {
            span {
              background-color: var(--bs-white);
              color: var(--breaker-bay);
            }
          }
          span {
            padding: 10px 20px;
            display: flex;
            @extend .smooth;
            border-radius: 5px;
          }
        }
      }
      .booking-nav-item {
        .book-btn {
          border: 1px var(--theme-green) solid;
          font-size: 14px;
          border-radius: 0.25rem;
          line-height: 1.7;
          background-color: var(--theme-green);
          color: var(--bs-white);
          &:hover,
          &:focus {
            background-color: var(--dark-500) !important;
            border-color: var(--dark-500) !important;
          }
        }
      }
      .logo-item {
        width: 242px;
        margin: 0 85px 0 100px !important;
        .nav-link {
          padding: 0 80px;
          &::before {
            content: none;
          }
        }
        .logo-fixed {
          display: none;
          top: 0;
        }
        .logo-sticky {
          top: -52px;
        }
      }
    }
  }
  .mobile-book-now {
    .book-btn {
      border: 1px solid var(--theme-green);
      font-size: 14px;
      border-radius: 0.25rem;
      line-height: 1.7;
      background-color: var(--theme-green);
      &:hover,
      &:focus {
        background-color: var(--tallow);
        border-color: var(--tallow);
        outline: 0;
        box-shadow: none;
      }
    }
  }
  &.sticky {
    padding-top: 0;
    padding-bottom: 0;
    background-color: var(--breaker-bay);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.25);
    &::after {
      content: none;
    }
    .navbar-nav {
      padding-bottom: 0;
    }
    .atmosphere-centered-logo {
      img.logo-fixed {
        display: block;
      }
      .logo-sticky {
        display: none;
      }
    }
    .header-wrap {
      &::before,
      &::after {
        content: none;
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .logo-item {
          .logo-fixed {
            display: inline;
            top: -26px;
          }
          .logo-sticky {
            display: none;
          }
        }
      }
    }
  }
  navbar-toggler {
    @extend .smooth;
    &:focus {
      outline: 0;
      box-shadow: none;
      &:not(:focus-visible) {
        box-shadow: none;
      }
    }
  }
}
.atmosphere-hero-video {
  .scroll-down {
    bottom: 100px;
    span {
      display: block;
      line-height: 1;
      font-size: 18px;
      animation: arrow 2s infinite;
      -o-animation: arrow 2s infinite;
      -ms-animation: arrow 2s infinite;
      -moz-animation: arrow 2s infinite;
      -webkit-animation: arrow 2s infinite;
      &:nth-child(2) {
        font-size: 24px;
      }
      &:nth-child(3) {
        font-size: 29px;
      }
      &.arrow1 {
        animation-delay: -1s;
        -webkit-animation-delay: -1s;
      }
      &.arrow2 {
        animation-delay: -0.5s;
        -webkit-animation-delay: -0.5s;
      }
      &.arrow3 {
        animation-delay: 0s;
        -webkit-animation-delay: 0s;
      }
    }
  }
  @keyframes arrow {
    0% {
      opacity: 0;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
}
.atmosphere-book-now {
  .parent {
    &.highlight {
      height: 350px;
    }
  }
  padding: 24px 30px;
  max-width: 1630px;
  width: calc(100% - 100px);
  box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
  backdrop-filter: blur(10px);
  margin-top: -80px;
  button {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 10px 6px;
    background-color: var(--bs-white);
    border: 1px solid var(--border-color);
    text-overflow: ellipsis;
    -webkit-transition: border-color 0.2s ease;
    transition: border-color 0.2s ease;
    text-align: left;
    color: var(--gondola);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .guests-input {
    button {
      cursor: pointer;
      &::before,
      &::before {
        content: " ";
        width: 12px;
        height: 2px;
        border-radius: 2px;
        background-color: var(--gondola);
        position: absolute;
        top: 19px;
      }
      &::before {
        right: 15px;
        height: 7px;
        background-image: url(../images/thin-down-arrow.svg);
        background-color: transparent;
        top: 50px;
      }
      &::after {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        right: 21px;
      }
      &.open {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        &::after {
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
  }
  .guests-input__options {
    position: absolute;
    width: 100%;
    background-color: var(--bs-white);
    -webkit-box-shadow: rgba(72, 72, 72, 0.2) 0 15px 20px;
    box-shadow: rgba(72, 72, 72, 0.2) 0 15px 20px;
    border-radius: 2px;
    overflow: hidden;
    height: 0;
    opacity: 0;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear;
    &.open {
      opacity: 1;
      height: 146px;
    }
    > div {
      padding: 10px 0;
      text-align: center;
      &:first-child {
        padding-top: 35px;
      }
      &:last-child {
        padding-bottom: 35px;
      }
    }
  }
  .guests-input__ctrl {
    display: inline-block;
    border: 1px solid var(--border-color);
    font-size: 20px;
    color: var(--gondola);
    padding: 3px 8px;
    line-height: 20px;
    border-radius: 2px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    &.disabled {
      color: var(--border-color);
      border-color: var(--border-color);
      cursor: default;
    }
  }
  .guests-input__value {
    display: inline-block;
    padding: 0 10px;
    width: 100px;
    cursor: default;
    span {
      display: inline-block;
      padding-right: 5px;
    }
  }
  .submit-form {
    margin-top: 30px;
  }
  .booking-form {
    .row {
      .col {
        &:last-child {
          max-width: 171px;
        }
      }
    }
  }
  .calendar-header {
    background-color: var(--theme-green);
    color: var(--bs-white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  #prevBtn,
  #nextBtn {
    background: none;
    border: none;
    color: var(--bs-white);
    cursor: pointer;
    font-size: 22px;
    width: auto;
  }
  #monthYear {
    font-size: 18px;
    font-weight: bold;
    color: var(--bs-white);
  }
  .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    padding: 10px;
    font-size: 14px;
  }
  .day {
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .day.current {
    background-color: var(--theme-green);
    color: var(--bs-white);
  }
  .day.selected {
    background-color: var(--theme-green);
    color: var(--bs-white);
  }
  #dateInput {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }
  .discount-wrap {
    .form-control {
      border-color: var(--border-color) !important;
    }
    .dropdown-menu {
      transform: none !important;
      inset: auto !important;
      max-width: 376px;
      padding: 20px 28px;
      border-radius: 4px;
      margin-top: 2px;
    }
  }
  form {
    label {
      font-size: 12px;
      color: var(--gondola);
      opacity: 1 !important;
    }
    .form-control {
      font-size: 14px;
      padding: 13.5px 15px;
      color: var(--gondola) !important;
      &:focus,
      &.show {
        border-color: var(--theme-green);
        background-color: var(--bs-white);
        color: var(--bs-body-color);
      }
    }
    select.form-control,
    button.form-control,
    .checkin-out-date {
      background-image: url(../images/thin-down-arrow.svg);
      background-repeat: no-repeat;
      background-size: 12px;
    }
    button.form-control {
      &:hover,
      &:focus,
      &:visited {
        background-color: transparent;
        color: inherit;
        border-color: var(--border-color);
      }
      &:focus {
        border-color: var(--breaker-bay);
      }
      &::after {
        content: none;
      }
    }
    .dropdown {
      .guest-form-control {
        background-image: none;
        border-color: var(--border-color) !important;
      }
      &.show {
        .guest-form-control {
          border-radius: 4px;
        }
      }
    }
  }
  .submit-col {
    max-width: 185px;
  }
  .discount-dropdown {
    .form-control {
      padding-top: 8.5px;
      padding-bottom: 8.5px;
      max-width: 120px;
    }
  }
}
.small-heading {
  color: var(--breaker-bay);
}
.heading-space {
  letter-spacing: 2.5px;
}
.our-sub-brands {
  padding-left: 37.5px;
  padding-right: 37.5px;
}
.cardBg3 {
  background: rgba(176, 124, 86, 0.8);
}
.cardBg4 {
  background-color: rgba(84, 157, 174, 0.8);
}
.cardBg5 {
  background-color: rgba(135, 120, 155, 0.8);
}
.cardBg6 {
  background-color: rgba(99, 130, 88, 0.8);
}
.cardBg7 {
  background-color: rgba(184, 128, 122, 0.8);
}
.cardBg8 {
  background-color: rgba(198, 161, 88, 0.8);
}
.cardBg9 {
  background-color: rgba(84, 108, 125, 0.8);
}
.sub-card-title {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  font-size: 24px;
  line-height: 1.3;
  height: 125px;
}
.sub-card {
  margin: 0 auto;
  box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.1);
  border-radius: 0 0 10px 10px;
  backdrop-filter: blur(5px);
  bottom: -295px;
  &:hover,
  &:focus {
    cursor: pointer;
  }
  .cardOneBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--medium-wood);
      }
    }
  }
  .cardTwoBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--glacier);
      }
    }
  }
  .cardThreeBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--rose);
      }
    }
  }
  .cardFourBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--tallow);
      }
    }
  }
  .cardFiveBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--chatelle);
      }
    }
  }
  .cardSixBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--fire-brick);
      }
    }
  }
  .cardSevenBg ~ .sub-card-para {
    .card-link {
      svg {
        color: var(--bismark);
      }
    }
  }
}
.sub-card-para {
  padding: 10px 35px 30px;
  p {
    line-height: 1.9;
    margin: 0;
    position: relative;
    color: var(--bs-white);
    min-height: 185px;
  }
  hr {
    border-width: 2px;
    opacity: 1;
    top: 0;
    width: 80px;
    margin: 35px auto 0;
    position: relative;
    @extend .smooth;
    &.cardBorderOneBg {
      border-color: var(--medium-wood);
    }
    &.cardBorderTwoBg {
      border-color: var(--glacier);
    }
    &.cardBorderThreeBg {
      border-color: var(--rose);
    }
    &.cardBorderFourBg {
      border-color: var(--tallow);
    }
    &.cardBorderFiveBg {
      border-color: var(--chatelle);
    }
    &.cardBorderSixBg {
      border-color: var(--fire-brick);
    }
    &.cardBorderSevenBg {
      border-color: var(--bismark);
    }
  }
  .card-link {
    top: 15px;
    a {
      color: var(--bs-white);
      text-transform: uppercase;
      text-decoration: none;
      @extend .smooth;
      display: flex;
      justify-content: center;
      &:hover,
      &:focus {
        transform: translateX(10px);
        opacity: 0.85;
        display: flex;
      }
      svg {
        margin-left: 20px;
      }
    }
  }
}
.sub-brand-card {
  background-color: var(--theme-green);
  img {
    width: 100%;
    max-width: 280px;
  }
}
.feature-card {
  &:hover,
  &:focus {
    .sub-card {
      bottom: 0;
    }
    .sub-card-para {
      padding-top: 0;
    }
  }
}
.vertically-heading {
  z-index: 0;
  top: 580px;
  left: -40px;
  width: 0;
  h1,
  h2 {
    transform: rotate(-90deg);
    letter-spacing: 15px;
  }
}
.hotel-heading-wrap {
  padding-left: 133px;
  padding-right: 133px;
}
.view-all {
  font-size: 14px;
  color: var(--gondola);
  letter-spacing: 2px;
  align-items: center;
  @extend .smooth;
  svg {
    font-size: 14px;
  }
  &:focus,
  &:hover {
    transform: translate(10px);
    color: var(--theme-green) !important;
    svg {
    }
  }
}
.hotels-resorts {
  padding-bottom: 50px;
  .slide-wrap {
    width: calc(100% - 800px);
  }
  .bottom-right-images {
    .thumb-wrap {
      padding: 35px 40px;
      max-width: 230px;
      width: 100%;
      height: auto;
      margin: 0 15px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(10px);
      align-items: center;
      border-radius: 10px;
      border: 1px solid var(--border-color);
      box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
      span {
        font-size: 14px;
      }
    }
    .thumb-img-box {
      height: 135px;
      margin: 30px auto 0;
      border-radius: 10px;
      width: 118px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .left-control-btns {
    width: 140px;
  }
  .right-slide-progress-bar {
    width: calc(100% - 150px);
    padding: 0 42px 0 2px;
    margin: 0 auto;
    .linebar-slide {
      width: 100%;
      height: 1px;
      background-color: var(--theme-green);
      display: flex;
    }
    .active-slide-bar {
      top: -1px;
      height: 3px;
      background-color: var(--theme-green);
    }
    .line-slide-status {
      width: calc(100% - 60px);
      padding-right: 5px;
    }
    .slide-number {
      width: 60px;
      span {
        font-size: 32px;
        color: var(--theme-green);
        letter-spacing: 3px;
      }
    }
  }
  .img-container-overlay {
    border-radius: 10px;
    height: 100%;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 70%;
      height: 100%;
      background: linear-gradient(
        109.13deg,
        rgba(9, 38, 48, 1) 0%,
        rgba(9, 38, 48, 0) 86.66679263114929%
      );
      border-radius: 10px;
    }
  }
  .slide-demo-box {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 70%;
      height: 100%;
      background: linear-gradient(
        109.13deg,
        rgba(9, 38, 48, 1) 0%,
        rgba(9, 38, 48, 0) 86.66679263114929%
      );
      border-radius: 10px;
    }
  }
  .hotel-resort-mobile-slide {
    border-radius: 10px;
    overflow: hidden;
    h1,
    .h1 {
      letter-spacing: 2px;
      margin-bottom: 20px;
      font-size: 45px;
    }
    span {
      font-size: 18px;
      letter-spacing: 3.5px;
    }
    small {
      font-size: 16px;
      letter-spacing: 3.5px;
    }
  }
  .mobile-slides-hotel-resort {
    .slick-slider {
      padding-bottom: 5px;
    }
    .slick-slide {
      padding: 0 15px;
    }
    .slick-prev,
    .slick-next {
      top: initial;
      bottom: 0;
      border: 1px var(--theme-green) solid;
      background-color: transparent !important;
      width: 60px;
      height: 60px;
      background-size: 12px !important;
      transform: none;
      margin: 0 auto;
    }
    .slick-prev {
      background-image: url(../images/prev-arrow.svg) !important;
      right: 0;
      left: -150px;
    }
    .slick-next {
      background-image: url(../images/next-arrow.svg) !important;
      left: 0;
      right: -150px;
    }
    .slick-dots {
      bottom: 0;
      width: 50px;
      height: 50px;
      position: relative;
      left: 0;
      right: 0;
      margin: 30px auto 0;
      li {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        transition: all 900ms ease-in-out 0s;
        -o-transition: all 900ms ease-in-out 0s;
        -ms-transition: all 900ms ease-in-out 0s;
        -moz-transition: all 900ms ease-in-out 0s;
        -webkit-transition: all 900ms ease-in-out 0s;
        &.slick-active {
          opacity: 1;
          visibility: visible;
        }
      }
      button {
        background-color: transparent;
        font-size: 25px;
        color: var(--theme-green);
        width: 100%;
        height: 100%;
        padding: 0;
        &::before {
          content: none;
        }
      }
    }
  }
}
#nextbutton,
#nextbuttondummy {
  background-color: transparent;
  &:focus {
    background-color: transparent;
  }
}
.hotels-resorts-slides {
  padding: 0 50px;
  overflow: hidden;
  .slick-item {
    img {
      width: 100%;
    }
    .img-container {
      border-radius: 10px;
      &::before {
        content: "";
        opacity: 0.6;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: var(--dark-theme);
      }
    }
  }
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-top: -105px;
    text-align: right;
    display: flex !important;
    max-width: 1040px;
    margin-left: auto;
    li {
      min-width: 230px;
      width: 100%;
      height: auto;
      margin: 0 15px;
      a {
        display: flex;
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        align-items: center;
        border-radius: 10px;
        border: 1px solid var(--border-color);
        box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
        .thumb-wrap {
          padding: 35px 55px;
          span {
            font-size: 14px;
          }
        }
        .thumb-img-box {
          height: 135px;
          margin: 30px 0 0;
          border-radius: 10px;
          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
  .slide-caption {
    margin: 55px 0 0 130px;
    z-index: -1;
    h1,
    .h1 {
      letter-spacing: 10px;
      font-size: 56px;
    }
    span {
      font-size: 20px;
      letter-spacing: 7px;
    }
    .slide-small-text {
      background-image: url("../images/brush-stoke.png");
      background-repeat: no-repeat;
      background-position: center bottom;
    }
    small {
      font-size: 18px;
      letter-spacing: 4px;
    }
    a {
      font-size: 16px;
      svg {
        font-size: 18px;
      }
      &:hover,
      &:focus {
        color: var(--bs-white) !important;
        opacity: 0.85 !important;
        transform: translateX(10px);
        svg {
          transform: translateX(0);
          color: var(--bs-white) !important;
        }
      }
    }
  }
}
.gallery-slides {
  .slick-prev,
  .slick-next {
    opacity: 1;
    &::before {
      content: "";
      width: 20px;
      height: 20px;
      display: flex;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .slick-prev {
    &::before {
      background-image: url("../images/prev.svg");
    }
  }
  .slick-next {
    &::before {
      background-image: url("../images/next.svg");
    }
  }
  .slick-list {
    overflow-x: hidden;
    padding-bottom: 100px;
  }
}
.atmosphere-gallery {
  .gallery-card {
    max-width: 432px;
  }
}
.gallery-card {
  padding: 0 12px 25px;
  .gallery-text-box {
    padding: 30px 15px 30px;
    @extend .smooth;
    width: calc(100% - 116px);
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 10px 10px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(10px);
    bottom: -117px;
    overflow: hidden;
    p {
      font-size: 14px;
      line-height: 1.7;
      margin: 16px 0 0;
    }
  }
  .view-more-destination {
    @extend .smooth;
    transform: translateY(25px);
    overflow: hidden;
    margin-top: 35px;
    opacity: 0;
    height: 0;
    overflow: hidden;
    a {
      padding: 11.5px 34px;
    }
  }
  &:hover,
  &:focus {
    .gallery-text-box {
    }
    cursor: pointer;
    .view-more-destination {
      transform: translateY(0);
      opacity: 1;
      height: 46px;
    }
  }
}
.special-offers-slides {
  overflow: hidden;
  @extend .gallery-slides;
  .slick-list {
    margin-left: -12px;
    margin-right: -12px;
  }
  .slick-arrow {
    top: initial;
    bottom: 95px;
    z-index: 1;
    &.slick-prev {
      left: -750px;
      right: 0;
      margin: auto;
    }
    &.slick-next {
      left: 0;
      right: -750px;
      margin: auto;
    }
  }
  .gallery-text-box {
    max-width: 620px;
    margin-top: -215px;
    @extend .smooth;
    transform: scale(0);
    opacity: 0;
    padding-bottom: 30px !important;
    padding-left: 35px;
    padding-right: 35px;
    h4 {
      font-size: 40px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .slick-current,
  .slick-active {
    .gallery-text-box {
      transform: scale(1);
      opacity: 1;
    }
  }
}
.special-offers {
  .gallery-text-box {
    small,
    span {
      font-size: 15px;
      color: var(--gondola);
    }
    h4 {
      color: var(--gondola);
    }
    p {
      a {
        color: var(--theme-green);
      }
    }
  }
}
.sustainability {
  .sustainability-commitment {
    background-image: url("../images/sustainability.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 20px 215px;
    border-radius: 10px;
    span {
      letter-spacing: 3px;
    }
    p {
      max-width: 610px;
      font-size: 14px;
    }
  }
  .sustain-card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 10px 10px 40px 0 rgba(110, 154, 149, 0.2);
    backdrop-filter: blur(10px);
    p {
      color: var(--fiord);
      font-size: 14px;
      line-height: 1.7;
      min-height: 96px;
    }
    .card-image {
      height: 70px;
      width: 70px;
      img {
        width: auto;
      }
    }
  }
  .commitment-feature {
    margin-top: -115px;
  }
}
.ask-form {
  h2 {
    color: var(--gondola);
  }
  p {
    color: var(--fiord);
    max-width: 880px;
  }
  .gdpr-compliance {
    p {
      max-width: 100%;
    }
  }
  form {
    .custom-label,
    .label-focused {
      color: var(--fiord);
      position: absolute;
      left: 20px;
      top: 0;
      padding: 0 10px;
      background-color: var(--bs-white);
      z-index: -1;
      @extend .smooth;
      transform: translateY(18.5px);
      -o-transform: translateY(18.5px);
      -ms-transform: translateY(18.5px);
      -moz-transform: translateY(18.5px);
      -webkit-transform: translateY(18.5px);
      pointer-events: none;
      &.label-focused {
        transform: translateY(-12px);
        -o-transform: translateY(-12px);
        -ms-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        -webkit-transform: translateY(-12px);
        color: var(--theme-green);
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
    .custom-label,
    .label-focused-email {
      color: var(--fiord);
      position: absolute;
      left: 20px;
      top: 0;
      padding: 0 10px;
      background-color: var(--bs-white);
      z-index: -1;
      @extend .smooth;
      transform: translateY(18.5px);
      -o-transform: translateY(18.5px);
      -ms-transform: translateY(18.5px);
      -moz-transform: translateY(18.5px);
      -webkit-transform: translateY(18.5px);
      pointer-events: none;
      &.label-focused-email {
        transform: translateY(-12px);
        -o-transform: translateY(-12px);
        -ms-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        -webkit-transform: translateY(-12px);
        color: var(--theme-green);
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
    .form-control {
      border-color: var(--green-border);
      padding: 17px 30px;
      box-shadow: none;
      border-radius: 6px;
      background-color: transparent;
      @extend .smooth;
      color: var(--fiord);
      &:focus {
        border-color: var(--theme-green);
      }
    }
    .PhoneInputInput {
      padding: 0;
      border: 0 !important;
      outline: 0 !important;
    }
    .PhoneInputCountry {
      margin-right: 20px;
      border-right: 1px var(--green-border) solid;
      padding-right: 20px;
    }
    .PhoneInputCountrySelectArrow {
      border: 0;
      height: 10px;
      width: 10px;
      background-image: url("../images/down-arrow.svg");
      background-repeat: no-repeat;
      transform: none;
      opacity: 1;
      margin-left: 17px;
    }
    .PhoneInputCountryIcon--border {
      border: 0;
      box-shadow: none;
    }
    .theme-btn {
      padding: 13.5px 44px;
      color: var(--theme-green) !important;
    }
    .check-terms {
      width: calc(100% - 417px);
      span {
        color: var(--fiord);
        font-size: 14px;
        width: calc(100% - 22px);
        padding-left: 15px;
      }
      input[type="checkbox"] {
        position: absolute;
        left: -99999px;
        top: 0;
      }
      .custom-check {
        width: 22px;
        height: 22px;
        border: 1px solid var(--fiord);
        border-radius: 4px;
        position: relative;
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          left: 4px;
          top: 4px;
          position: absolute;
        }
      }
      input:checked + .custom-check {
        &::before {
          background-color: var(--fiord);
        }
      }
    }
  }
}
footer {
  .footer-newsletter {
    background-color: var(--theme-green);
    h6 {
      font-size: 18px;
      letter-spacing: 4px;
    }
    form {
      max-width: 480px;
      width: 100%;
      margin-left: 75px;
      .form-control {
        border-radius: 6px 0 0 6px;
        font-size: 14px;
        color: var(--bs-white);
        height: 50px;
        &:focus {
          outline: 0;
          border-color: var(--border-color);
        }
      }
      button {
        border-radius: 0 6px 6px 0;
        border-color: var(--border-color);
        border-left: 0;
        padding: 0 27px;
        background-color: transparent;
        color: var(--bs-white);
        @extend .smooth;
        svg {
          margin: 0 15px 0 0;
        }
        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:active,
        &:focus-visible {
          background-color: var(--bs-white) !important;
          border-color: var(--bs-white) !important;
          color: var(--theme-green) !important;
          box-shadow: none;
        }
        &:not(.btn-check) + .btn:active {
          background-color: var(--bs-white);
          border-color: var(--bs-white);
          color: var(--theme-green);
        }
      }
      ::-webkit-input-placeholder {
        color: var(--bs-white);
      }
      ::-moz-placeholder {
        color: var(--bs-white);
      }
      :-ms-input-placeholder {
        color: var(--bs-white);
      }
      :-moz-placeholder {
        color: var(--bs-white);
      }
    }
  }
  .mein-footer {
    background-color: var(--gondola);
    .footer-wrap {
      padding: 30px 55px 0;
    }
    .footer-logo {
      img {
        max-width: 198px;
        width: 100%;
      }
    }
    .footer-col {
      span {
        font-size: 14px;
        letter-spacing: 1.75px;
      }
      address {
        font-size: 14px;
        color: var(--bs-white);
        max-width: 198px;
        opacity: 0.8;
      }
      .phone-footer {
        a {
          text-decoration: none;
          color: var(--sinbad);
          font-size: 20px;
          letter-spacing: 2px;
        }
      }
      .email-footer {
        white-space: nowrap;
        a {
          letter-spacing: 0;
          color: var(--bs-white);
          font-size: 14px !important;
          opacity: 0.8;
        }
      }
      .icon-phone {
        opacity: 0.8;
        margin-right: 10px;
        color: var(--theme-green);
      }
    }
    .useful-links-col {
      padding-left: 100px;
    }
    .footer-links {
      a {
        text-decoration: none;
        color: var(--bs-white);
        font-size: 14px;
        opacity: 0.8;
        display: inline-block;
        &:not(:last-child) {
          span {
            padding-right: 32px;
          }
        }
        span {
          letter-spacing: 0;
        }
      }
    }
  }
  .bottom-footer {
    padding: 20px 55px;
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    margin: 30px 0 0;
    font-size: 14px;
    color: var(--suva-gray);
    .footer-social {
      a {
        color: var(--bs-white);
        font-size: 17px;
        padding: 0 25px;
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }
  }
  .copyright,
  .atmosphere-core-logo {
    max-width: 375px;
    width: 100%;
    a {
      color: var(--suva-gray);
    }
  }
  .atmosphere-core-logo {
    text-align: right;
  }
}
.header-space {
  padding-top: 135px;
}
.inner-cover {
  .our-story {
    width: 100%;
  }
  img {
    object-fit: cover;
  }
  &::after {
    content: "";
    background: linear-gradient(
      180deg,
      rgba(0, 123, 161, 0.4) 0%,
      rgba(0, 123, 161, 0) 100%
    );
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 40%;
  }
}
.page-inner {
  padding: 60px 0;
  z-index: 1;
  .breadcrumb {
    display: flex;
    align-items: center;
    a {
      color: var(--bs-white);
      text-decoration: none;
      font-size: 14px;
    }
    .breadcrumb-item + .breadcrumb-item {
      color: var(--bs-white);
      font-size: 14px;
      &::before {
        content: ">";
        color: var(--bs-white);
        padding: 0 15px;
      }
    }
  }
}
.page-title {
  h2 {
    letter-spacing: 22.5px;
    font-family: "Georgia-Bold";
    border-top: 1px rgba(255, 255, 255, 0.5) solid;
    border-bottom: 1px rgba(255, 255, 255, 0.5) solid;
    line-height: 1.6;
  }
}
.dashed-heading {
  h2,
  h3,
  h4,
  h5 {
    letter-spacing: 9px;
    &::before {
      position: absolute;
      left: 0;
      right: 0;
      height: 2px;
      bottom: 24px;
      width: 100%;
      content: "";
      border-top: 1px dashed var(--theme-green);
    }
    span {
      background-color: var(--bs-white);
      position: relative;
      padding-right: 22px;
    }
  }
  h4 {
    letter-spacing: 4px;
    &::before {
      bottom: 13px;
    }
  }
  h5 {
    &::before {
      bottom: 11px;
    }
    span {
      padding-right: 8px;
    }
  }
}
.dashed-heading {
  h3 {
    letter-spacing: 6px;
    &::before {
      bottom: 20px;
      border-top: 1px dashed var(--theme-green);
    }
    span {
      padding-right: 10px;
    }
  }
}
.value-card-img,
.feature-left-image {
  img {
    border-radius: 10px;
  }
}
.value-card-text {
  .value-card-title {
    padding: 0 45px;
    margin: 105px 0 185px;
  }
  padding: 0 35px;
  span {
    font-size: 24px;
  }
  p {
    font-size: 14px;
    color: var(--gondola);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 10px 10px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(10px);
    padding: 25px 20px;
    margin: 0;
    height: 252px;
    line-height: 1.8;
  }
}
.right-feature-text-col {
  max-width: 805px;
  .right-feature-wrap {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    padding: 103px 65px 55px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 6px 6px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(15px);
    width: 100%;
  }
  .top-head-text {
    h3 {
      color: var(--theme-green);
    }
    span {
      @extend h3;
      font-size: 20px;
    }
  }
  .feature-text-para {
    margin: 60px 0 0;
    &::after {
      content: "";
      width: 145px;
      height: 3px;
      background-color: var(--theme-green);
      display: flex;
      margin: 60px auto 0;
    }
    p {
      font-size: 20px;
      font-family: "Georgia-Regular";
      color: var(--gondola);
      line-height: 1.9;
    }
  }
}
.book-now-modal {
  .modal-dialog {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--bs-modal-margin) * 2);
  }
  .modal-content {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
    padding: 25px 45px 35px;
  }
  .modal-dialog {
    max-width: 670px;
  }
  .btn-close {
    right: 10px;
    top: 10px;
    position: absolute;
  }
  form {
    label {
      font-size: 14px;
    }
    .form-control {
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      padding: 17px 20px;
    }
  }
  .form-submit {
    .theme-btn {
      background-color: var(--dark-green);
      border-color: var(--dark-green);
      padding: 18.5px 47px;
      &:hover,
      &:focus {
        background-color: var(--fiord);
        border-color: var(--fiord);
      }
    }
  }
}
.modal-title {
  letter-spacing: 5px;
}
.btn-close {
  color: var(--bs-white);
  background-image: url("../images/close.svg");
  background-repeat: no-repeat;
  opacity: 1;
}
.modal-open {
  header,
  footer,
  section {
    filter: blur(10px);
  }
}
.input-number {
  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
.input-number-increment,
.input-number-decrement {
  background-color: transparent;
  border: 0;
  width: 35px;
  color: var(--gondola);
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 1;
}
.input-number-increment {
  left: 0;
  text-align: right;
}
.input-number-decrement {
  right: 0;
  text-align: left;
}
.sub-brands-tabs {
  padding-left: 50px;
  padding-right: 50px;
  overflow: hidden;
  .rts___tabs___container {
    max-width: 1394px;
  }
  .rts___tabs {
    max-width: 1320px;
    margin: 0 auto;
  }
  .tab-contain {
    margin: 0 9px;
  }
}
.sub-brands-tabs {
  .rts___tab {
    margin: 0;
    border: 1px var(--border-color) solid;
    padding: 7px;
    border-radius: 16px;
    background: transparent !important;
    box-shadow: none;
    padding: 0;
    border: 0;
    position: relative;
    @extend .smooth;
    &.rts___tab___selected {
      .tab-name {
        color: var(--bs-white) !important;
      }
    }
    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 10px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid transparent;
      bottom: -9px;
      z-index: 1;
    }
    &.rts___tab___selected {
      background-color: var(--theme-green);
      border-color: var(--theme-green);
      &::before {
        border-top-color: var(--theme-green);
      }
    }
    &:first-child {
      .gradnt-brdr {
        background-color: var(--atmosphere-subbrand);
      }
      .tab-name {
        background-color: var(--atmosphere-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmosphere-subbrand);
        border-color: var(--atmosphere-subbrand);
        &::before {
          border-top-color: var(--atmosphere-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmosphere-subbrand);
        .tab-name {
          color: var(--atmosphere-subbrand);
        }
      }
    }
    &:nth-child(2) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--byatmosphere-subbrand-nw);
      }
      &.rts___tab___selected {
        background-color: var(--byatmosphere-subbrand-nw);
        border-color: var(--byatmosphere-subbrand-nw);
        &::before {
          border-top-color: var(--byatmosphere-subbrand-nw);
        }
      }
      &:hover {
        border-color: var(--byatmosphere-subbrand);
        .tab-name {
          color: var(--byatmosphere-subbrand);
        }
      }
    }
    &:nth-child(3) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--atmosphereexp-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmosphereexp-subbrand);
        border-color: var(--atmosphereexp-subbrand);
        &::before {
          border-top-color: var(--atmosphereexp-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmosphereexp-subbrand);
        .tab-name {
          color: var(--atmosphereexp-subbrand);
        }
      }
    }
    &:nth-child(4) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--atmospheresign-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmospheresign-subbrand);
        border-color: var(--atmospheresign-subbrand);
        &::before {
          border-top-color: var(--atmospheresign-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmospheresign-subbrand);
        .tab-name {
          color: var(--atmospheresign-subbrand);
        }
      }
    }
    &:nth-child(5) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--atmospherenature-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmospherenature-subbrand);
        border-color: var(--atmospherenature-subbrand);
        &::before {
          border-top-color: var(--atmospherenature-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmospherenature-subbrand);
        .tab-name {
          color: var(--atmospherenature-subbrand);
        }
      }
    }
    &:nth-child(7) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--atmosphereurbn-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmosphereurbn-subbrand);
        border-color: var(--atmosphereurbn-subbrand);
        &::before {
          border-top-color: var(--atmosphereurbn-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmosphereurbn-subbrand);
        .tab-name {
          color: var(--atmosphereurbn-subbrand);
        }
      }
    }
    &:nth-child(6) {
      .gradnt-brdr,
      .tab-name {
        background-color: var(--atmospherehrtg-subbrand);
      }
      &.rts___tab___selected {
        background-color: var(--atmospherehrtg-subbrand);
        border-color: var(--atmospherehrtg-subbrand);
        &::before {
          border-top-color: var(--atmospherehrtg-subbrand);
        }
      }
      &:hover {
        border-color: var(--atmospherehrtg-subbrand);
        .tab-name {
          color: var(--atmospherehrtg-subbrand);
        }
      }
    }
  }
  .tab-feature-img {
    width: 100%;
    height: auto;
    min-width: 312px;
    width: 312px;
    border-radius: 16px !important;
    img {
      object-fit: cover;
      width: 100%;
      min-width: 312px;
      height: 160px;
    }
  }
  .gradnt-brdr {
    padding: 1px;
    height: 60px;
    border-radius: 12px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    &::after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.25) 32%,
        rgba(255, 255, 255, 0.75) 100%
      );
    }
  }
  .tab-name {
    white-space: wrap;
    padding: 5px 20px;
    border-radius: 12px;
    width: 100%;
    font-size: 16px;
    position: relative;
    z-index: 1;
    @extend .smooth;
  }
  .rts___nav___btn___container {
    &:first-child {
      .rts___nav___btn {
        background-image: url("../images/prev.svg");
      }
    }
  }
  .rts___nav___btn {
    border: 0;
    width: 20px;
    background-color: transparent;
    background-image: url("../images/next.svg");
    background-repeat: no-repeat;
    background-position: center;
    svg {
      display: none;
    }
    &:hover,
    &:focus {
      background-color: transparent;
      svg {
        stroke: var(--bs-black);
      }
    }
    &:disabled {
      opacity: 0.75;
    }
  }
}
.tab-text-view {
  padding: 35px 70px 80px;
  margin-top: -100px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-size: auto;
  border: 1px solid;
  &::before {
    background-repeat: no-repeat;
  }
  .top-logo {
    &::before {
      content: "";
      left: 0;
      right: 0;
      top: calc(50% + 10px);
      position: absolute;
      height: 1px;
      border-top: 1px var(--theme-green) dashed;
    }
  }
  .tabpara {
    max-width: 825px;
  }
  .hotel-view-tab {
    max-width: 865px;
    border-radius: 30px;
    padding: 23px;
    border: 1px var(--border-color) solid;
    @extend .smooth;
    position: relative;
    box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.05);
  }
  .left-thumb-img {
    border-radius: 20px;
    overflow: hidden;
    width: 348px;
    img {
      width: 100%;
    }
  }
  .right-hotel-text {
    width: calc(100% - 387px);
    padding-left: 60px;
    h4 {
      letter-spacing: 5px;
      color: var(--gondola);
      text-transform: uppercase;
    }
    p {
      letter-spacing: 3px;
      @extend h4;
      margin: 30px 0 0;
    }
    .visit-now {
      margin: 60px 0 0;
      a {
        text-decoration: none;
        font-size: 14px;
        color: var(--gondola);
        letter-spacing: 2px;
        font-weight: normal;
        display: flex;
        align-items: center;
        @extend .smooth;
        &:hover,
        &:focus {
          color: var(--dark-green);
          transform: translateX(10px);
        }
      }
    }
  }
}
.tab-top-header {
  background-repeat: no-repeat;
  background-size: auto;
  position: relative;
}
.discover-left {
  .row {
    .card-col {
      .discover-card {
        flex-direction: column;
      }
      &:nth-child(3n + 2) {
        .discover-card {
          flex-direction: column-reverse;
        }
      }
    }
  }
}
.brand1 {
  .tab-text-view {
    border: 1px rgba(110, 154, 149, 0.6) solid;
  }
}
.brand2 {
  .tab-text-view {
    border: 1px rgba(133, 177, 187, 0.6) solid;
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--byatmosphere-subbrand-nw);
    }
  }
  .download-brochure {
    background-color: var(--ocean-blue);
  }
}
.brand3 {
  .tab-text-view {
    border: 1px rgba(211, 168, 153, 0.6) solid;
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--atmosphereexp-subbrand);
    }
  }
  .download-brochure {
    background-color: var(--purple-bright);
  }
}
.brand4 {
  .tab-text-view {
    border: 1px rgba(172, 159, 117, 0.6) solid;
  }
  .resorts-view-all {
    position: relative;
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--atmospheresign-subbrand);
    }
  }
  .download-brochure {
    background-color: var(--light-brown);
  }
}
.brand5 {
  .tab-text-view {
    border-color: rgba(170, 163, 175, 0.6);
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--atmospherenature-subbrand);
    }
  }
  .download-brochure {
    background-color: var(--green-light);
  }
}
.brand6 {
  .tab-text-view {
    border-color: rgba(160, 54, 29, 0.26);
    background-color: var(--bs-white);
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--atmospherehrtg-subbrand);
    }
  }
  .download-brochure {
    background-color: var(--orange-light);
  }
}
.brand7 {
  .tab-text-view {
    border-color: rgba(60, 104, 123, 0.3);
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      background-color: var(--atmosphereurbn-subbrand);
    }
  }
  .download-brochure {
    background-color: var(--slate-gray);
  }
}
.resorts-view-all {
  gap: 28px;
}
.resort-tab-slides {
  .slick-list {
    margin-left: -14px;
    margin-right: -14px;
  }
  .slick-slide {
    padding-left: 14px;
    padding-right: 14px;
  }
  .slick-arrow {
    z-index: 1;
    top: 45%;
    &::after,
    &::before {
      color: var(--dark-500);
    }
    &.slick-prev {
      &::before {
        background-image: url("../images/prev.svg");
        content: "";
        width: 20px;
        height: 20px;
        display: flex;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    &.slick-next {
      &::after {
        background-image: url("../images/next.svg");
        content: "";
        width: 20px;
        height: 20px;
        display: flex;
        background-repeat: no-repeat;
        background-position: center;
      }
      &::before {
        content: none;
      }
    }
  }
  .resort-item {
    margin-left: auto;
    margin-right: auto;
  }
}
.resort-item {
  border-radius: 30px;
  border: 1px var(--border-color) solid;
  padding: 22px 22px 35px;
  box-shadow: 0 6px 10px 1px rgba(0, 0, 0, 0.05);
  background-color: var(--bs-white);
  position: relative;
  width: calc(100% / 4);
  min-width: 288.5px;
}
.resort-pic {
  border-radius: 20px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.resort-name-location {
  text-align: center;
  margin: 35px 0 0;
  h6 {
    font-size: 19px;
    letter-spacing: 1px;
    height: 50px;
  }
  span {
    font-size: 14px;
    letter-spacing: 3px;
  }
}
.rts___tabs___container {
  max-width: 1320px;
  margin: auto;
}
.atmosphere-discover {
  background-position: 12.2% 43%, 7% calc(100% - 110px), 89% calc(100% - 110px);
  position: relative;
}
.discover-left,
.discover-right {
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  .discover-card {
    display: flex;
    .discover-card-image {
      min-height: 375px;
      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .discover-card-body {
        &::before {
          left: initial;
          right: -13px;
          transform: rotate(180deg);
          -o-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform: rotate(180deg);
        }
      }
    }
  }
  .discover-card-image,
  .discover-card-body {
    width: 50%;
  }
  .discover-card-body {
    padding: 25px 60px;
    box-shadow: inset 0 0 0 1px var(--border-color);
    min-height: 292.583px;
    background-color: var(--bs-white);
    &::before {
      position: absolute;
      left: -13px;
      top: calc(50% - 17px);
      width: 52px;
      height: 34px;
      background-image: url("../images/discover-card-triangle.svg");
      background-repeat: no-repeat;
      z-index: 1;
    }
  }
  .discover-feature-icon {
    width: 88px;
    height: 88px;
    background-color: var(--atmosphere-subbrand);
    img {
      width: 30px;
    }
  }
  .discover-title {
    font-size: 20px;
    font-weight: bold;
    color: var(--gondola);
    min-height: 60px;
    width: 100%;
    text-align: center;
  }
  .discover-text {
    p {
      font-size: 14px;
      font-weight: normal;
      min-height: 120px;
    }
  }
}
.discover-right {
  border-radius: 0 10px 10px 0;
  .discover-card {
    flex-wrap: wrap;
  }
  .discover-card-image,
  .discover-card-body {
    width: 100%;
  }
  .discover-card-body {
    &::before {
      right: 0;
      left: 0;
      margin: 0 auto;
      top: -6px;
      transform: rotate(90deg);
      -o-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
  }
}
.download-brochure {
  height: 292.583px;
  background-color: var(--medium-wood-one);
  font-size: 20px;
  letter-spacing: 6px;
  min-height: 375px;
}
.contact-card {
  border-radius: 20px;
  border: 1px solid var(--border-color);
  box-shadow: 0px -3px 0 var(--theme-green) inset;
  padding: 27px 30px;
  h4 {
    color: var(--theme-green);
  }
  p {
    color: var(--gondola);
    letter-spacing: 4.5px;
    margin: 0;
  }
  .content-text {
    min-height: 70px;
    a {
      color: var(--gondola);
      text-decoration: none;
      letter-spacing: 4.5px;
      word-break: break-all;
    }
  }
}
.contact-via-social {
  gap: 24px;
  a {
    width: 88px;
    height: 88px;
    border-radius: 20px;
    border: 1px var(--theme-green) solid;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--theme-green);
    font-size: 25px;
    @extend .smooth;
    img {
      filter: invert(58%) sepia(11%) saturate(827%) hue-rotate(124deg)
        brightness(97%) contrast(88%);
      @extend .smooth;
      width: 23px;
    }
    &:hover,
    &:focus {
      background-color: var(--theme-green);
      color: var(--bs-white);
      img {
        filter: none;
      }
    }
  }
}
.map-location {
  iframe {
    border-radius: 20px;
    overflow: hidden;
  }
}
.site-visit-links {
  svg {
    transform: rotate(45deg);
  }
}
.sustainability-cards {
  overflow: hidden;
  .right-feature-text-col {
    max-width: 611px;
    .right-feature-wrap {
      padding: 60px 50px;
      margin-top: 70px;
    }
    .top-head-text {
      h4 {
        color: var(--gondola);
        letter-spacing: 3px;
        font-size: 28px;
      }
    }
    .feature-text-para {
      margin: 30px 0 0;
      p {
        font-family: arial;
        font-size: 16px;
        opacity: 0.8;
      }
      &::after {
        content: none;
      }
    }
  }
}
.learnmore {
  a {
    font-size: 14px;
    color: var(--theme-green);
    @extend .smooth;
    display: inline-block;
    &:hover,
    &:focus {
      color: var(--dark-theme);
      transform: translateX(10px);
    }
  }
}
.slide-arrow-btns {
  width: 60px;
  height: 60px;
  border: 1px var(--theme-green) solid;
  cursor: pointer;
}
.slider-bottom-actions {
  margin-top: -90px;
  padding-bottom: 50px;
}
.brands-slide {
  overflow: hidden;
}
.environmental-tourism {
  background-image: url("../images//environmental-tourism.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
  .environmental-tourism-card {
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.86);
    box-shadow: 6px 6px 20px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(15px);
    width: 100%;
    max-width: 688px;
    padding: 65px;
  }
}
.offers-tabs {
  .rts___tab {
    min-width: 251px;
    margin: 0 5px;
    padding: 7px;
    border: 1px solid var(--border-color);
    border-radius: 200px;
    .tab-feature-img {
      width: 45px;
      height: 45px;
      min-width: initial;
      border-radius: 50% !important;
      img {
        height: auto;
        min-width: initial;
      }
    }
    .tab-name {
      width: calc(100% - 45px);
      padding: 0 20px;
      background: transparent !important;
      font-size: 14px;
      white-space: nowrap;
    }
    &:hover {
      border-color: var(--border-color) !important;
      .tab-name {
        color: var(--bs-body-color) !important;
      }
    }
    &.rts___tab___selected {
      &:hover {
        border-color: var(--theme-green) !important;
        .tab-name {
          color: var(--bs-white) !important;
        }
      }
    }
  }
  .rts___tabs___container {
    justify-content: center;
  }
  .offer-img {
    height: 500px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
}
.offers-tabs {
  .offer-info-card {
    max-width: 665px;
    .right-feature-wrap {
      padding: 30px 35px;
    }
    .hotel-heading-wrap {
      span {
        font-size: 15px;
        color: var(--gondola);
      }
      h2 {
        letter-spacing: 2px;
        font-weight: 700;
        margin: 10px 0 5px;
      }
      p {
        font-size: 14px;
        margin: 18px 0 25px;
        word-break: break-word;
        a {
          letter-spacing: 0;
          color: var(--theme-green);
        }
      }
    }
  }
  .offer-img {
    img {
      border-radius: 10px;
    }
  }
  &.sub-brands-tabs {
    .rts___tab {
      &.rts___tab___selected {
        background-color: var(--theme-green) !important;
        border-color: var(--theme-green);
        &::before {
          border-top-color: var(--theme-green);
        }
      }
    }
  }
}
.all-offers-list {
  .tab-top-header {
    &:nth-child(odd) {
      .offer-info-card {
        right: 0;
      }
      padding: 90px 0;
    }
    &:nth-child(even) {
      .col-md-9 {
        margin-left: 25%;
      }
    }
    &:first-child {
      padding-top: 0 !important;
    }
  }
}
.offer-details {
  .dashed-heading {
    h2 {
      letter-spacing: 4.5px;
    }
  }
}
.large-img-offer {
  img {
    border-radius: 10px;
  }
}
.offer-points {
  ul {
    padding-left: 15px;
  }
  li {
    color: var(--theme-green);
    padding: 5px 0;
    &::marker {
      font-size: 20px;
    }
    span {
      color: var(--dim-gray);
      padding-left: 4px;
    }
  }
}
.media-center-tabs {
  .rts___tabs___container {
    justify-content: center;
    margin: 0 auto 60px;
    width: 100%;
  }
  .rts___tab {
    padding: 9px;
    min-width: 310px;
    &:hover {
      border-color: var(--border-color) !important;
      .tab-name {
        color: var(--bs-body-color) !important;
      }
    }
    &.rts___tab___selected {
      background-color: var(--theme-green) !important;
      border-color: var(--theme-green) !important;
      &:hover {
        .tab-name {
          color: var(--bs-white) !important;
        }
      }
      &::before {
        border-top-color: var(--theme-green) !important;
      }
      .tab-feature-img {
        background-color: var(--bs-white);
        img {
          filter: invert(42%) sepia(14%) saturate(627%) hue-rotate(124deg)
            brightness(95%) contrast(87%);
        }
      }
    }
  }
  .tab-name {
    font-size: 16px;
  }
  .tab-feature-img {
    background-color: var(--theme-green);
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 28px;
    }
  }
  .tab-name {
    width: calc(100% - 60px);
  }
}
.media-para {
  max-width: 765px;
}
.press-releases-cards {
  .row {
    .col-lg-6 {
      &:nth-child(even) {
        .card-body {
          margin-left: auto;
        }
      }
    }
  }
  .card-image {
    height: 385px;
    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .card-body {
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 7px 7px 14px 0 rgba(115, 157, 152, 0.2);
    backdrop-filter: blur(7px);
    border-radius: 8px;
    width: calc(100% - 114px);
    margin: -115px 26px 0;
    padding: 40px 35px 35px;
  }
  h4 {
    line-height: 1.5;
    color: var(--gondola);
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card-text {
    height: 180px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    p {
      width: 100%;
    }
  }
  .read-more {
    a {
      color: var(--theme-green);
      font-size: 14px;
      @extend .smooth;
      display: inline-block;
      &:hover,
      &:focus {
        color: var(--dark-theme);
        transform: translateX(10px);
      }
    }
  }
}
.show-more {
  margin: 60px 0 0;
}
.btn {
  &.theme-outline-btn {
    background-color: transparent;
    color: var(--theme-green);
    padding-left: 38px;
    padding-right: 38px;
  }
}
.press-details {
  .press-img {
    margin: 0 0 40px;
  }
  .press-title {
    width: calc(100% - 200px);
    h3 {
      color: var(--gondola);
    }
  }
  .press-date {
    span {
      font-size: 14px;
    }
  }
  .press-full-article {
    margin: 50px 0 0;
  }
  .press-article-pagination {
    margin: 40px 0 0;
  }
}
.more-articles-sidebar {
  padding-left: 125px;
  .dashed-heading {
    h5 {
      letter-spacing: 0;
    }
  }
  .thumb-card-title {
    h6 {
      line-height: 1.5;
    }
  }
  .thumb-card-img {
    span {
      background: rgba(255, 255, 255, 0.85);
      box-shadow: 7px 7px 14px 0 rgba(115, 157, 152, 0.2);
      backdrop-filter: blur(7px);
      font-size: 13px;
      margin: 8px;
    }
  }
}
.thumb-articles {
  .thumb-press-card {
    margin-bottom: 55px;
  }
}
.sub-brand-subtitle {
  h4 {
    letter-spacing: 2px;
  }
}
.media-center-tabs {
  form {
    .select-form {
      max-width: 360px;
      margin: 0 12px;
    }
    .form-control {
      font-size: 14px;
      padding: 13.5px 20px;
      color: var(--fiord);
    }
  }
}
.award-card {
  border-color: var(--green-border) !important;
  padding: 45px 60px;
  .award-by {
    span {
      min-height: 42px;
      font-size: 14px;
      max-width: 260px;
    }
  }
}
.collateral-card {
  border-color: var(--green-border) !important;
  border-radius: 20px;
  padding: 30px;
  .collateral-text {
    padding: 0 15px;
    width: calc(100% - 167px);
    h5 {
      font-size: 22px;
      color: var(--gondola);
    }
    p {
      color: var(--fiord);
      margin: 0;
      line-height: 1.5;
    }
  }
  .collateral-docs-type {
    background-color: var(--see-icon);
    border-radius: 20px;
    width: 112px;
    height: 112px;
  }
  .download-docs {
    a {
      width: 55px;
      height: 55px;
      border-radius: 15px;
      background-color: var(--theme-green);
    }
  }
}
.under-construction-img {
  img {
    max-width: 500px;
  }
}
.entrance-animation {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.entrance-animation-entered {
  opacity: 1;
  transform: translateY(0);
}
.green-globe-icon {
  top: -50px;
  right: -1px;
}
.img-container {
  padding-left: 50px;
  padding-right: 50px;
  height: 450px !important;
  min-width: 100%;
}
.initialhw {
  .img-container-overlay {
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}
.slide-demo-box {
  height: 450px;
  border-radius: 10px;
  position: relative;
}
.guest-dropdown {
  border: 1px var(--border-color) solid !important;
  border-radius: 0;
  .guests-input_options {
    margin: 20px auto 0;
    padding: 0 15px;
    label {
      text-transform: capitalize;
      text-align: left;
      width: 100%;
      font-size: 14px;
      color: var(--gondola);
      margin: 0 0 4px;
    }
    .guest-card {
      border: 1px var(--border-color) solid;
      font-size: 13px;
      padding: 4px 15px;
    }
    .guests-input__ctrl {
      border: 0;
      padding: 5px;
      font-size: 13px;
    }
  }
  .guest-dropdown-item {
    padding: 0;
  }
}
.mob-slide-image {
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.our-values {
  overflow: hidden;
}
.atmosphere-experience-await {
  .paragraph {
    p {
      margin-bottom: 0;
    }
  }
}
.ask-to-atmosphere {
  form {
    .form-submit {
      .btn {
        background-color: var(--theme-green);
        border-color: var(--theme-green);
        color: var(--bs-white) !important;
        &:hover,
        &:focus {
          background-color: var(--tallow);
          border-color: var(--tallow);
        }
      }
    }
  }
}
.pressfullview {
  a {
    color: var(--breaker-bay);
  }
}
.childs-dropdwon {
  top: 3px !important;
  bottom: initial !important;
  transform: none !important;
  width: 100%;
  border: 1px var(--border-color) solid !important;
  border-radius: 4px;
}
.resort-item {
  .visit-now {
    margin: 30px 0 0;
    a {
      text-decoration: none;
      font-size: 14px;
      color: var(--gondola);
      letter-spacing: 2px;
      font-weight: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      @extend .smooth;
      &:hover,
      &:focus {
        color: var(--dark-green);
        transform: translateX(10px);
      }
    }
  }
}
.special-offers {
  .gallery-card {
    .card-image {
      height: 530px;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
}
.sustainability-cards {
  .sustainrow {
    &:nth-child(even) {
      justify-content: flex-end;
      margin-top: 90px;
      .right-feature-text-col {
        left: 0;
      }
    }
    &:last-child {
      margin-bottom: 90px;
    }
  }
}
.brand-height {
  height: 77px;
  overflow: hidden;
  img {
    min-height: 77px;
    object-fit: cover;
  }
}
.CookieConsent {
  max-width: 500px;
  border-radius: 5px;
  padding: 20px 30px;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%, 9999990px);
  width: 95%;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}
.overlayclass {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.atmosphere-cookies {
  .CookieConsent {
    top: initial;
    bottom: 10px;
    transform: none;
    left: 10px;
    max-width: calc(100% - 20px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      min-width: 100%;
    }
  }
  #rcc-confirm-button {
    display: none;
  }
  .cookies-text {
    width: calc(100% - 285px);
    padding-right: 40px;
  }
  .cookies-actions {
    width: 285px;
    display: flex;
    justify-content: flex-end;
  }
}
.PhoneInput--focus {
  border-color: var(--theme-brown) !important;
}
.gallery-home-section {
  .slick-list {
    padding-bottom: 215px;
  }
}
.heading-icon {
  img {
    max-width: 120px;
  }
}
.terms-condition-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 764px;
}
.conditions-inner-wrapper {
  ul {
    padding-left: 20px;
    li {
      padding-left: 0;
      display: list-item;
      list-style-type: disc;
      color: var(--fiord);
    }
  }
}
.condition-space {
  padding-top: 40px;
}
.policy-text-color {
  color: var(--gondola);
}
.page-not-found {
  padding: 230px 0;
  .error-text {
    .error-small-text {
      color: var(--theme-brown);
    }
    h2 {
      font-size: 50px;
    }
  }
}
.page-note-found-header {
  background-color: var(--gondola);
}
.award-by {
  min-height: 50px;
  font-weight: 700;
}
.award-text {
  padding: 0 55px;
}
.getting-ready {
  min-height: 100vh;
  overflow: auto;
  padding-left: 95px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../images/atmosphere-bg.jpg);
}
.getting-ready-inner {
  min-height: 100vh;
  padding: 70px 90px 100px;
  width: 100%;
  max-width: 718px;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );
}
.getting-ready-content {
  margin-top: 159px;
}
.getting-ready-content h1 {
  font-size: 40px;
  color: #333333;
  font-family: Georgia;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.5px;
}
.getting-ready-content p {
  font-size: 18px;
  max-width: 534px;
  color: #333333;
  font-family: arial;
  line-height: 1.5;
  letter-spacing: 0.3px;
}
.getting-ready-content .book-now-btn {
  padding: 17px 36px;
  color: white;
  font-size: 14px;
  display: inline-block;
  border-radius: 4px;
  margin-top: 40px;
  text-decoration: none;
  text-transform: uppercase;
  background-color: #6e9a95;
  transition: all 500ms ease-in-out 0s;
}
.getting-ready-content .book-now-btn:hover {
  background-color: #031c2d;
  color: #ffffff;
}
.getting-ready-content .book-now-btn:focus {
  background-color: #031c2d;
  color: #ffffff;
}
.footer-info {
  height: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 0 80px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.footer-info.ahr {
  background-color: #6e9a95;
}
.footer-info a {
  font-size: 14px;
  color: #ffffff;
  text-decoration: none;
}
.footer-info span {
  margin-right: 8px;
}
.arrow-hide {
  color: var(--bs-body-color) !important;
  &::before {
    content: none !important;
  }
}
.guestmodal-open-body {
  * {
    filter: blur(0) !important;
  }
  .guestmodal {
    --bs-modal-width: 100%;
    .modal-content {
      border-radius: 0;
      border: 0;
      background: #f0f0f0;
    }
    &.show {
      display: flex !important;
      align-items: flex-end;
    }
    .modal-dialog {
      width: 100%;
      margin: 0;
    }
    .guest-dropdown {
      border: 0 !important;
    }
  }
  .guest-dropdown {
    width: 100%;
  }
}
.input-number {
  user-select: none;
}
.atmosphere-book-now {
  .css-wmy1p7-ReactDropdownSelect {
    &:focus,
    &:focus-within,
    &:hover {
      border-color: var(--border-color);
    }
    .css-z2hyoe-ItemComponent.react-dropdown-select-item-selected {
      background-color: rgba(0, 0, 0, 0.05);
      color: var(--dark-500);
    }
    .css-z2hyoe-ItemComponent {
      &:hover,
      &:focus {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
  .react-dropdown-select-content {
    input {
      font-size: 14px;
    }
    &hover {
      border-color: var(--border-color);
    }
  }
  .daterangepicker {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    border: 0 !important;
    margin-top: 0 !important;
    &.show-calendar {
      border: 1px solid #ddd !important;
      margin-top: 7px !important;
      &.opensright {
        &::before,
        &::after {
          display: flex !important;
        }
      }
    }
    &.opensright {
      &::before,
      &::after {
        display: none !important;
      }
    }
  }
  .datepicker-open ~ .daterangepicker {
    display: flex !important;
    width: fit-content;
  }
  .css-xhfeyy-DropDown,
  .css-1sr0ihv-DropDown {
    top: 52px;
    padding: 10px 0;
    box-shadow: none;
    border-radius: 4px;
    border-color: var(--border-color);
    font-size: 16px;
    color: var(--dark-500);
  }
  .react-dropdown-select-item {
    padding: 10px 20px;
  }
}
.cstm-arrow {
  background-image: url("../images/thin-down-arrow.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 20px) center;
}
.custom-select {
  .select-options {
    border: var(--border-color) solid 1px;
    margin-top: 3px;
    padding: 8px 0;
    border-radius: 4px;
    color: var(--dark-500);
    font-size: 16px;
    .select-option {
      padding: 10px 20px;
      cursor: pointer;
      &.selected {
        background-color: var(--theme-green);
      }
    }
  }
}
.ahr-guests-save-btn {
  display: flex;
  justify-content: center;
  padding: 8px 8px 24px;
  button {
    width: auto;
    padding: 6.5px 25px !important;
    &::before {
      background-image: none !important;
    }
  }
}

.sc {
  //   background: orange;
}
.mios {
  //   background: orange;
  -webkit-overflow-scrolling: touch;
  overflow: auto !important;
  overflow-y: scroll !important;
}
// .mios::-webkit-scrollbar {
//   width: 10px; /* Set the width of the scrollbar */
// }

// .sc::-webkit-scrollbar-track {
//   background-color: red; /* Light background */
//   border-radius: 10px; /* Rounded corners */
// }

// /* Style the draggable thumb */
// .sc::-webkit-scrollbar-thumb {
//   background-color: #888; /* Darker color for the thumb */
//   border-radius: 10px; /* Rounded corners */
// }
// .sc .react-custom-scrollbars-track {
//   background-color: #f1f1f1; /* Track background color */
// }

// /* Style for the scrollbar thumb (the part you drag) */
// .sc .react-custom-scrollbars-thumb {
//   background-color: red; /* Thumb color */
//   border-radius: 10px;
// }

// /* Style for the thumb on hover */
// .sc .react-custom-scrollbars-thumb:hover {
//   background-color: #555; /* Thumb color on hover */
// }
