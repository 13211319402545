@media (max-width: 1800px) {
  .brandone {
    .atmosphere-discover {
      &::after {
        background-position: calc(100% - 50px) calc(50% + 10px), 54% bottom;
      }
    }
  }
}
@media (max-width: 1670px) {
  .brandone {
    .tab-text-view {
      &::after {
        background-position: left calc(100% - 40px), 30% 0;
      }
    }
    .atmosphere-discover {
      &::before {
        right: -80px;
      }
      &::after {
        background-position: calc(100% - 10px) calc(50% + 10px), 100% bottom;
      }
    }
  }
  .brand7 {
    .tab-text-view {
      &::before {
        left: -68px;
      }
    }
  }
}
@media (max-width: 1580px) {
  .atmosphere-book-now {
    .submit-col {
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
      .submit-form {
        .btn {
          &.theme-btn {
            padding: 13.5px 25px;
          }
        }
      }
    }
  }
  header {
    .atmosphere-living-nav {
      .navbar-nav {
        .booking-nav-item {
          top: -10px;
          right: -50px;
        }
      }
    }
  }
  .brandone {
    .tab-text-view {
      &::after {
        background-position: left calc(100% - 40px), 21px 0;
        background-size: 51px, 47px;
      }
      &::before {
        left: -54px;
        background-size: 45px;
      }
    }
    .atmosphere-discover {
      &::before {
        right: -65px;
        background-size: 50px;
      }
      &::after {
        background-position: 0 calc(50% + 10px), 100% bottom;
        background-size: 100%;
        width: 72px;
        left: -72px;
      }
    }
  }
  footer {
    .mein-footer {
      .footer-links {
        a {
          &:not(:last-child) {
            span {
              padding-right: 24px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1530px) {
  .cstm-arrow {
    background-position: calc(100% - 12px) center;
  }
}
@media (max-width: 1507px) {
  .atmosphere-book-now {
    .discount-dropdown {
      .form-control {
        max-width: 90px;
      }
    }
  }
}
@media (max-width: 1493px) {
  .atmosphere-book-now {
    padding: 20px 18px;
    .daterangepicker {
      .drp-calendar {
        max-width: 234px;
        &.left,
        &.right {
          padding: 5px;
        }
      }
    }
  }
}
@media (max-width: 1450px) {
  .atmosphere-book-now {
    form {
      .form-control {
        padding-right: 35px;
        white-space: nowrap;
        margin-bottom: 0;
      }
    }
  }
  .sub-card-para {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sub-card-title {
    font-size: 22px;
  }
  .sub-brands-tabs {
    padding-left: 25px;
    padding-right: 25px;
  }
  .slide-demo-box,
  .img-container {
    height: 590px !important;
  }
  .hotels-resorts-slides {
    .slide-caption {
      h1,
      .h1 {
        font-size: 52px;
      }
    }
  }
  .hotels-resorts {
    .slide-wrap {
      width: calc(100% - 650px);
    }
  }
  .bottom-right-images {
    max-width: 650px;
  }
  div#moveableDiv {
    .thumb-wrap {
      margin-left: 639px;
    }
  }
  .vertically-heading {
    left: -30px;
  }
  .section-padding {
    padding: 80px 0;
  }
  .guest-dropdown {
    .guests-input_options {
      .guest-card {
        padding: 4px 8px;
      }
    }
  }
}
@media (max-width: 1399px) {
  .atmosphere-book-now {
    .discount-dropdown {
      .form-control {
        max-width: 120px;
      }
    }
  }
  .discount-wrap {
    .dropdown-container {
      position: relative;
      .discount-dropdown {
        position: absolute !important;
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
  }
  .booking-form-input {
    .dropdown-container {
      position: relative;
      .guest-dropdown {
        position: absolute !important;
        background-color: var(--bs-white);
        z-index: 1;
      }
    }
  }
  .ahr-datepicker-wrapper {
    position: relative;
  }
  .atmosphere-book-now {
    .daterangepicker {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
      &.show-calendar {
        &.opensright {
          &::before,
          &::after {
            display: block !important;
          }
        }
      }
      &.opensright {
        &::before,
        &::after {
          display: none !important;
        }
      }
    }
    .parent {
      &.highlight {
        height: 545px;
      }
    }
  }
  header {
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-ul.start-nav-list {
          padding-right: 30px;
        }
        .nav-ul.end-nav-list {
          padding-left: 30px;
        }
      }
    }
  }
  .value-card-text {
    .value-card-title {
      margin: 35px 0 115px;
      padding: 0 15px;
    }
  }
  .right-feature-text-col {
    .right-feature-wrap {
      padding: 60px 60px 40px;
    }
    .feature-text-para {
      margin: 45px 0 0;
      &::after {
        margin: 45px auto 0;
      }
    }
  }
  .sub-card {
    bottom: -265px;
  }
  footer {
    .mein-footer {
      .footer-links {
        a {
          &:not(:last-child) {
            span {
              padding-right: 30px;
            }
          }
        }
      }
    }
    .footer-col {
      justify-content: flex-end;
    }
    .location {
      flex-wrap: wrap;
      justify-content: flex-start;
      .phone-footer {
        margin-left: 0 !important;
        width: 100%;
        text-align: left !important;
      }
    }
  }
  .discover-left,
  .discover-right {
    .discover-card-body {
      padding: 20px 30px;
    }
    .discover-card-image {
      min-height: 292.83px;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  .tab-text-view {
    padding: 35px 50px 80px;
  }
  .resort-item {
    padding: 15px 15px 35px;
    min-width: 230.5px;
  }
  .media-center-tabs {
    .tab-feature-img {
      width: 60px;
      height: 60px;
    }
    .tab-name {
      width: calc(100% - 60px);
    }
  }
  .press-releases-cards {
    .card-body {
      padding: 40px 30px 35px;
    }
  }
  .more-articles-sidebar {
    padding-left: 75px;
  }
  .collateral-card {
    padding: 20px;
  }
  .sustainability-cards {
    .right-feature-text-col {
      .right-feature-wrap {
        margin-top: 20px;
      }
    }
  }
  .our-values:not(.sustainability-cards) {
    .feature-left-image {
      height: 480px;
    }
  }
  .feature-left-image {
    height: 430px;
    img {
      object-fit: cover;
      height: 100%;
    }
  }
  .brandone {
    .tab-text-view {
    }
  }
  .value-card-text {
    p {
      height: 300px;
    }
  }
  .discover-left,
  .discover-right {
    .discover-text {
      p {
        min-height: 131px;
      }
    }
  }
  .sub-card-title {
    height: 100px;
  }
  .sub-card-para {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    p {
      min-height: 185px;
    }
    .card-link {
      margin-top: 7px !important;
    }
  }
  .award-year-title {
    font-size: 14.5px;
  }
  .award-card {
    padding: 45px 40px;
  }
  .resort-tab-slides {
    .resort-item {
      min-width: 275px;
    }
  }
  .offers-tabs {
    .rts___tab {
      .tab-name {
        width: calc(100% - 40px);
      }
      .tab-feature-img {
        width: 40px;
        height: 40px;
      }
    }
  }
  .custom-select {
    position: relative;
    .select-options {
      position: absolute;
      width: 100%;
      z-index: 1;
      background-color: var(--bs-white);
    }
  }
}
@media (max-width: 1366px) {
  header {
    .atmosphere-centered-logo {
      .logo-sticky {
        top: -40px;
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-item {
          margin: 0 4px;
          .nav-link {
            padding: 25.6px 0;
          }
        }
        .logo-item {
          max-width: 198px;
          margin: 0 60px 0 70px !important;
          img {
            max-width: 198px;
          }
        }
      }
    }
    &.sticky {
      .atmosphere-living-nav {
        .navbar-nav {
          .logo-item {
            .logo-fixed {
              top: -19px;
            }
          }
        }
      }
    }
  }
  .vertically-heading {
    left: -22px;
  }
  .heading-space {
    letter-spacing: 5px;
  }
  .hotel-heading-wrap {
    padding-left: 90px;
    padding-right: 90px;
  }
  .gallery-card {
    .gallery-text-box {
      width: calc(100% - 55px);
      padding: 30px 25px;
      bottom: -90px;
    }
  }
  footer {
    .mein-footer {
      .useful-links-col {
        padding-left: 70px;
      }
    }
  }
  .gallery-slides {
    .slick-list {
      padding-bottom: 145px;
    }
  }
  .header-space {
    padding-top: 110px;
  }
  .atmosphere-book-now {
    padding: 40px 30px;
  }
  .slide-demo-box,
  .img-container {
    height: 500px !important;
  }
  .hotels-resorts {
    .right-slide-progress-bar {
      padding-right: 15px;
      .line-slide-status {
        width: calc(100% - 40px);
      }
      .slide-number {
        width: 40px;
      }
    }
  }
  .press-releases-cards {
    .card-text {
      height: 170px;
    }
  }
  .gallery-card {
    .gallery-text-box {
      p {
        min-height: 95px;
      }
    }
  }
  .gallery-home-section {
    .slick-list {
      padding-bottom: 160px;
    }
  }
  .page-not-found {
    padding: 230px 0 180px;
  }
  .atmosphere-book-now {
    form {
      .form-control {
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 1199px) {
  h1,
  .h1 {
    font-size: 42px;
  }
  h2,
  .h2 {
    font-size: 35px;
  }
  h3,
  .h3 {
    font-size: 29px;
  }
  header {
    padding: 15px 12px;
    .header-wrap {
      &::before,
      &::after {
        width: calc(50% - 130px);
      }
    }
    .atmosphere-centered-logo {
      img.logo-fixed {
        top: -19px;
      }
      .logo-sticky {
        top: -25px;
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-ul {
          width: calc(50% - 120px);
        }
        .nav-item {
          margin: 0 3px;
          .nav-link {
            font-size: 12.5px;
            padding: 23px 0;
            span {
              padding: 10px 10px;
            }
          }
        }
        .logo-item {
          padding-right: 5px;
          .nav-link {
            padding: 0 25px;
            img {
              max-width: 150px;
            }
          }
        }
      }
    }
    .atmosphere-centered-logo {
      width: 190px;
    }
  }
  .atmosphere-book-now {
    padding: 30px 35px;
    width: calc(100% - 25px);
    .submit-form {
      margin-top: 12px;
    }
    .submit-col {
      margin: auto;
    }
    .form-control {
      margin-bottom: 18px;
    }
  }
  .section-padding {
    padding: 75px 0;
  }
  .heading-space {
    letter-spacing: 2px;
  }
  .hotels-resorts-slides {
    padding: 0 30px;
  }
  .hotel-heading-wrap {
    padding: 0 17px;
  }
  .hotels-resorts-slides {
    .slick-dots {
      max-width: 590px;
      li {
        min-width: 160px;
        a {
          .thumb-wrap {
            padding: 25px 20px;
          }
          .thumb-img-box {
            margin: 15px 0 0;
          }
        }
      }
    }
    .slide-caption {
      h1,
      .h1 {
        font-size: 48px;
        letter-spacing: 5px;
      }
      span {
        font-size: 18px;
      }
    }
  }
  .sustainability {
    .sustain-card {
      .card-image {
        height: 50px;
        width: 50px;
        img {
          height: 100%;
        }
      }
    }
    .sustain-text-box {
      h5 {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .ask-form form {
    .check-terms {
      width: calc(100% - 187px);
    }
  }
  footer {
    .mein-footer {
      .footer-wrap {
        padding: 45px 30px 0;
      }
      .useful-links-col {
        padding-left: 0;
        margin: 35px 0 0;
      }
      .footer-logo {
        text-align: center;
        margin-bottom: 25px;
        img {
          max-width: 150px;
        }
      }
      .footer-col {
        .phone-footer {
          a {
            font-size: 18px;
          }
        }
      }
      .footer-links {
        a {
          &:not(:last-child) {
            span {
              padding-right: 24px;
            }
          }
        }
      }
    }
    .bottom-footer {
      padding: 25px 30px;
      margin: 25px 0 0;
      .footer-social {
        a {
          padding: 0 15px;
        }
      }
    }
  }
  .page-inner {
    padding: 40px 0;
  }
  .value-card-text {
    padding: 0 15px;
  }
  .value-card-text {
    .value-card-title {
      margin: 20px 0 70px;
      padding: 0 15px;
    }
    span {
      font-size: 21px;
    }
    p {
      padding: 20px 10px;
    }
  }
  .right-feature-text-col {
    max-width: 600px;
    .right-feature-wrap {
      padding: 40px 30px 25px;
    }
    .feature-text-para {
      margin: 25px 0 0;
      p {
        font-size: 18px;
      }
    }
  }
  .sub-brand-card {
    min-height: 456px;
  }
  .vertically-heading {
    position: relative !important;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    h1,
    h2 {
      transform: none;
      letter-spacing: 0;
      margin: 0 0 30px;
    }
  }
  .book-now-modal {
    form {
      .form-control {
        font-size: 14px;
        padding: 15px 15px;
      }
    }
  }
  .commitment-feature {
    .row {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .sub-brands-tabs {
    padding-left: 20px;
    padding-right: 20px;
  }
  .tab-text-view {
    width: calc(100% - 20px);
    margin: -50px auto 0;
    padding: 25px 30px 50px;
  }
  .tab-text-view {
    .hotel-view-tab {
      padding: 15px;
    }
  }
  .resorts-view-all {
    flex-wrap: wrap;
    .resort-item {
      width: calc(50% - 14px);
      padding-bottom: 25px;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      width: 70px;
      height: 70px;
    }
    .discover-card-body {
      padding: 20px 30px;
      min-height: 270px;
    }
    .discover-card-image,
    .download-brochure {
      height: 270px;
    }
  }
  .brandfour {
    &::before {
      content: none;
    }
  }
  .resort-name-location {
    margin: 25px 0 0;
  }
  .rts___nav___btn___container.display___md___none {
    display: flex !important;
  }
  .contact-via-social {
    a {
      width: 70px;
      height: 70px;
    }
  }
  .contact-card {
    padding: 25px 15px;
  }
  .contact-card {
    p {
      letter-spacing: 1px;
      line-height: 1.5;
    }
    .content-text {
      min-height: 50px;
      a {
        letter-spacing: 1px;
      }
    }
  }
  .environmental-tourism {
    .environmental-tourism-card {
      padding: 50px;
    }
  }
  .sustainability-cards {
    .right-feature-text-col {
      .right-feature-wrap {
        padding: 40px 35px;
      }
      .feature-text-para {
        margin: 20px 0 0;
      }
    }
  }
  .offers-tabs {
    .offer-info-card {
      .hotel-heading-wrap {
        h2 {
          margin: 5px 0;
        }
        p {
          margin: 13px 0 18px;
        }
      }
    }
  }
  .all-offers-list {
    .tab-top-header {
      &:nth-child(odd) {
        padding: 75px 0;
      }
    }
  }
  .media-center-tabs {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .rts___tabs___container {
      margin: 0 auto 40px;
    }
    .rts___tab {
      padding: 6px;
      min-width: 260px;
    }
    .tab-feature-img {
      width: 50px;
      height: 50px;
    }
    .tab-name {
      width: calc(100% - 50px);
      font-size: 15px;
    }
  }
  .press-releases-cards {
    .card-body {
      padding: 30px 20px 25px;
      width: calc(100% - 52px);
    }
  }
  .more-articles-sidebar {
    padding-left: 15px;
  }
  .press-details {
    .press-img {
      margin: 0 0 30px;
    }
    .press-full-article {
      margin: 30px 0 0;
    }
    .press-title {
      width: calc(100% - 120px);
    }
  }
  .btn {
    &.theme-outline-btn {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
  .thumb-articles {
    .thumb-press-card {
      margin-bottom: 35px;
    }
  }
  .sub-card {
    bottom: -230px;
  }
  .sub-card-para {
    p {
      min-height: 155px;
    }
  }
  .sub-card-title {
    padding: 0 10px;
  }
  .award-card {
    padding: 30px 40px;
  }
  .collateral-card {
    padding: 15px;
    .collateral-text {
      width: calc(100% - 138px);
      h5 {
        font-size: 20px;
        margin-bottom: 3px;
      }
      p {
        font-size: 15px;
        line-height: normal;
      }
    }
    .collateral-docs-type {
      width: 90px;
      height: 90px;
    }
    .download-docs {
      a {
        width: 48px;
        height: 48px;
      }
    }
  }
  .header-space {
    padding-top: 105px;
  }
  .sustainability-cards {
    .feature-left-image {
      height: 350px;
    }
  }
  .green-globe-icon {
    img {
      max-width: 100px;
    }
  }
  .img-container {
    padding-left: 30px;
    padding-right: 30px;
  }
  div#moveableDiv {
    .thumb-wrap {
      width: 190px;
      margin-left: 624px;
      padding: 25px 15px;
    }
  }
  .hotels-resorts {
    .bottom-right-images {
      .thumb-img-box {
        margin-top: 15px;
      }
    }
    .slide-wrap {
      padding-top: 70px;
    }
    &.section-padding {
      padding-bottom: 25px;
    }
    .slide-wrap {
      width: calc(100% - 440px);
    }
    .slide-caption {
      h1 {
        font-size: 45px;
      }
    }
  }
  .bottom-right-images {
    max-width: 440px;
  }
  .slider-bottom-actions {
    margin-top: -120px;
  }
  .slide-demo-box,
  .img-container {
    height: 430px !important;
  }
  .our-values:not(.sustainability-cards) {
    .feature-left-image {
      height: 430px;
    }
  }
  .special-offers {
    .gallery-card {
      .card-image {
        height: 430px;
      }
    }
  }
  .atmosphere-gallery {
    .slick-slide > div {
      padding-bottom: 115px;
    }
    .gallery-card {
      .gallery-text-box {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  .press-releases-cards {
    .card-text {
      height: 200px;
    }
  }
  .discover-left,
  .discover-right {
    .discover-text {
      p {
        min-height: 149px;
      }
    }
  }
  .gallery-card {
    .gallery-text-box {
      p {
        min-height: 75px;
      }
    }
  }
  .gallery-slides,
  .special-offers-slides {
    .slick-list {
      padding-bottom: 70px;
    }
  }
  .award-card {
    padding: 45px 20px;
  }
  .resort-tab-slides {
    .resort-item {
      min-width: 235px;
    }
  }
  .childs-dropdwon {
    position: absolute !important;
    top: calc(100% - 1px) !important;
  }
}
@media (max-width: 1023px) {
  .atmosphere-book-now {
    .datepicker-open ~ .daterangepicker {
      flex-direction: column;
    }
  }
}
@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 35px;
  }
  h2,
  .h2 {
    font-size: 31px;
  }
  h3,
  .h3 {
    font-size: 24px;
  }
  header {
    z-index: 99;
    padding: 20px 20px;
    .atmosphere-centered-logo {
      display: none;
    }
    .header-wrap {
      &::before,
      &::after {
        content: none;
      }
    }
    .device-logo {
      img {
        max-width: 175px;
      }
    }
    .atmosphere-living-nav {
      .navbar-nav {
        .nav-ul {
          width: 100%;
          padding: 0 !important;
        }
        .nav-item {
          width: 100%;
          margin: 0 0 3px;
          .nav-link {
            padding: 12px 0;
            span {
              padding: 0;
              background-color: transparent !important;
            }
            &::before {
              right: initial;
              margin: 0;
              left: 0;
              height: 2px;
            }
            &.active,
            &:hover,
            &:focus {
              &::before {
                width: 50px;
              }
            }
          }
        }
        .logo-item {
          display: none;
        }
      }
      .navbar-collapse {
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 6px;
        padding: 15px 25px;
        backdrop-filter: blur(5px);
      }
    }
    .mobile-book-now {
      margin-top: 4px;
      margin-right: 60px;
      .book-btn {
        padding: 7px 15px !important;
      }
    }
    &.sticky {
      padding-top: 12px;
      padding-bottom: 12px;
      .atmosphere-living-nav {
        .navbar-nav {
          .navbar-collapse {
            &.show {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .logo-device-col {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-toggler {
    border: 1px var(--bs-white) solid;
    padding: 3px 6px;
    position: absolute;
    right: 0;
    top: -41px;
    span {
      background-image: url("../images/bar.svg");
      background-size: 20px;
    }
  }
  .section-padding {
    padding: 60px 0;
  }
  .heading-icon {
    img {
      max-width: 45px;
    }
  }
  .heading-space {
    letter-spacing: 0;
  }
  .hotels-resorts-slides {
    .slick-dots {
      margin-top: 20px;
    }
  }
  .sustainability {
    .sustainability-commitment {
      padding: 60px 15px 185px;
    }
    .sustain-card {
      margin-bottom: 24px;
    }
    .commitment-feature {
      margin-top: -125px;
    }
  }
  .ask-form {
    form {
      .bottom-actions {
        flex-wrap: wrap;
      }
      .check-terms {
        width: 100%;
        margin-bottom: 20px;
      }
      .form-control {
        padding: 13px 20px;
        font-size: 14px;
      }
    }
    .form-submit {
      width: 100%;
      text-align: right;
      .theme-btn {
        width: auto;
      }
    }
  }
  footer {
    .footer-newsletter {
      h6 {
        white-space: nowrap;
        letter-spacing: 0;
      }
      form {
        margin-left: 20px;
      }
    }
    .footer-logo {
      text-align: center;
    }
    .mein-footer {
      .useful-links-col {
        margin-top: 0;
      }
    }
    .no-border {
      border-right: 0 !important;
    }
    .bottom-footer {
      flex-wrap: wrap;
      .copyright {
        order: 3;
        width: 100%;
        text-align: center;
        margin: 20px 0 0;
        max-width: 100%;
      }
      .footer-social {
        order: 1;
      }
      .atmosphere-core-logo {
        order: 2;
        max-width: 200px;
      }
    }
  }
  .location {
    text-align: center;
    flex-wrap: wrap;
    span {
      justify-content: center;
    }
    .phone-footer {
      width: 100%;
      &:first-child {
        margin-bottom: 15px;
        margin-top: 13px;
      }
    }
  }
  .page-title {
    h2 {
      letter-spacing: 15px;
    }
  }
  .header-space {
    padding-top: 110px;
  }
  .value-card {
    margin-bottom: 30px;
  }
  .value-card-text {
    .value-card-title {
      margin: 60px 0 70px;
      padding: 0 35px;
    }
  }
  .value-card-text {
    p {
      padding: 20px 25px;
      height: auto;
    }
  }
  .right-feature-text-col {
    max-width: 510px;
    .right-feature-wrap {
      padding: 25px 20px 20px;
    }
    .top-head-text {
      span {
        font-size: 18px;
      }
    }
    .feature-text-para {
      margin: 15px 0 0;
      p {
        font-size: 16px;
      }
      &::after {
        margin: 25px auto 0;
      }
    }
  }
  .sub-brand-card {
    min-height: 336px;
    padding: 0 20px;
  }
  .atmosphere-book-now {
    margin-top: -40px;
  }
  .tab-text-view {
    .left-thumb-img,
    .right-hotel-text {
      width: 100%;
      img {
        width: 100%;
      }
    }
    .right-hotel-text {
      margin: 20px 0 0;
      padding: 0;
      p {
        margin: 15px 0 0;
      }
      .visit-now {
        margin: 20px 0 0;
      }
    }
    .hotel-view-tab {
      padding: 25px;
    }
  }
  .discover-left {
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    .discover-card {
      &:nth-child(1) {
        .discover-card-body {
          border-radius: 0 10px 0 0;
        }
      }
    }
  }
  .discover-right {
    border-radius: 0 0 10px 10px;
    .discover-card {
      flex-direction: row-reverse;
    }
    .discover-card-image,
    .discover-card-body {
      width: 50%;
    }
    .discover-card-body {
      &::before {
        transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        right: -13px;
        left: initial;
        top: initial;
      }
    }
  }
  .download-brochure {
    min-height: initial;
  }
  .resorts-view-all {
    gap: 20px;
    .resort-item {
      width: 100%;
    }
  }
  .atmosphere-living-nav {
    .navbar-nav {
      padding: 0;
      .navbar-collapse {
        transform: translateY(30px);
        transition: all 400ms ease-in-out 0s;
        -o-transition: all 400ms ease-in-out 0s;
        -ms-transition: all 400ms ease-in-out 0s;
        -moz-transition: all 400ms ease-in-out 0s;
        -webkit-transition: all 400ms ease-in-out 0s;
        &.show {
          transform: translateY(15px);
        }
      }
    }
  }
  .contact-card {
    margin-bottom: 15px;
  }
  .dashed-heading {
    h3 {
      &::before {
        bottom: 13px;
      }
    }
  }
  .map-location {
    iframe {
      max-height: 250px;
    }
  }
  .dashed-heading {
    h3 {
      letter-spacing: 0;
    }
  }
  .contact-via-social {
    gap: 15px;
    a {
      width: 50px;
      height: 50px;
      font-size: 20px;
      img {
        width: 18px;
      }
    }
  }
  .sub-brands-tabs {
    padding: 0 20px 45px;
  }
  .atmosphere-discover {
    padding-top: 60px !important;
  }
  .gallery-card {
    .gallery-text-box {
      bottom: -120px;
    }
  }
  .special-offers-slides {
    .slick-arrow {
      &.slick-prev {
        left: -650px;
      }
      &.slick-next {
        right: -650px;
      }
    }
  }
  .environmental-tourism {
    .environmental-tourism-card {
      padding: 35px;
    }
  }
  .sustainability-cards {
    .feature-left-image {
      min-height: 380px;
      img {
        object-fit: cover;
        height: 380px;
      }
    }
  }
  .btn.theme-btn-outline {
    padding: 15px 25px;
    font-size: 18px;
  }
  .offers-tabs {
    .offer-info-card {
      .hotel-heading-wrap {
        h2 {
          margin: 5px 0;
        }
        p {
          margin: 13px 0 18px;
        }
      }
    }
  }
  .all-offers-list {
    .tab-top-header {
      &:nth-child(odd) {
        padding: 60px 0;
      }
      &:last-child {
        padding-bottom: 60px;
      }
    }
  }
  .offers-tabs {
    .offer-info-card {
      max-width: 525px;
      .right-feature-wrap {
        padding: 20px 30px;
      }
    }
    .rts___tab {
      min-width: 175px;
    }
  }
  .offer-img {
    height: 344px;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .dashed-heading {
    h2,
    h3,
    h4 {
      letter-spacing: 0;
    }
  }
  .media-center-tabs {
    padding-top: 45px;
    .rts___tabs___container {
      margin: 0 auto 30px;
    }
    .rts___tab {
      min-width: 220px;
    }
    .tab-feature-img {
      width: 40px;
      height: 40px;
      img {
        max-width: 20px;
      }
    }
    .tab-name {
      width: calc(100% - 40px);
      font-size: 14px;
    }
  }
  .press-releases-cards {
    .card-body {
      margin: -85px 26px 0;
    }
  }
  .more-articles-sidebar {
    padding-left: 0;
  }
  .press-details {
    .press-img {
      margin: 0 0 18px;
    }
    .press-title {
      width: 100%;
    }
    .press-full-article {
      margin: 15px 0 0;
    }
  }
  .btn {
    &.theme-outline-btn {
      padding: 10px 20px;
    }
  }
  .sub-card {
    bottom: -230px;
  }
  .sub-card-para {
    p {
      min-height: 155px;
      font-size: 15px;
    }
  }
  .sub-brand-subtitle {
    h4 {
      letter-spacing: 0;
    }
  }
  .media-center-tabs {
    form {
      .select-form {
        max-width: 300px;
      }
    }
  }
  .collateral-card {
    .collateral-text {
      width: calc(100% - 125px);
    }
    .collateral-docs-type {
      width: 80px;
      height: 80px;
    }
    .download-docs {
      a {
        width: 45px;
        height: 45px;
      }
    }
  }
  .header-space {
    padding-top: 65px;
  }
  .hotels-resorts {
    .slide-wrap {
      width: calc(100% - 340px);
    }
    .left-control-btns {
      width: 100px;
    }
    .right-slide-progress-bar {
      width: calc(100% - 100px);
      padding: 0 12px;
      .slide-number {
        span {
          font-size: 25px;
        }
      }
    }
    .slide-caption {
      h1 {
        font-size: 37px;
        letter-spacing: 0;
        padding-bottom: 8px !important;
        background-size: 28px;
      }
      span,
      small {
        font-size: 16px;
        letter-spacing: 2px;
      }
      a {
        font-size: 14px;
        margin-top: 15px !important;
        svg {
          font-size: 14px;
          margin-left: 6px !important;
        }
      }
    }
  }
  .bottom-right-images {
    max-width: 340px;
  }
  div#moveableDiv {
    .thumb-wrap {
      margin-left: 615px;
      width: 170px;
      span {
        font-size: 13px;
      }
    }
  }
  .slide-arrow-btns {
    width: 45px;
    height: 45px;
    img {
      max-width: 10px;
    }
  }
  .slide-demo-box,
  .img-container {
    height: 375px !important;
  }
  .our-values:not(.sustainability-cards) {
    .feature-left-image {
      height: 335px;
    }
  }
  .atmosphere-gallery {
    .slick-slide > div {
      padding-bottom: 145px;
    }
    .gallery-card {
      .card-image {
        height: 380px;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .gallery-text-box {
        h4 {
          font-size: 18.5px;
        }
      }
    }
  }
  .offers-tabs {
    .rts___tab {
      min-width: initial;
    }
  }
  .press-releases-cards {
    .card-text {
      height: auto;
    }
  }
  .media-center-tabs {
    padding-bottom: 45px;
  }
  .gallery-card {
    .gallery-text-box {
      p {
        min-height: 95px;
      }
    }
  }
  .gallery-slides,
  .special-offers-slides {
    .slick-list {
      padding-bottom: 50px;
    }
  }
  .error-img {
    margin-top: 60px;
  }
  .page-not-found {
    padding: 230px 0 100px;
  }
  .discover-left {
    .row {
      .card-col {
        &:nth-child(3n + 2) {
          .discover-card {
            flex-direction: column;
          }
        }
      }
    }
  }
  .offers-tabs {
    .rts___tab {
      .tab-name {
        width: calc(100% - 30px);
      }
      .tab-feature-img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
@media (max-width: 767px) {
  h1,
  .h1 {
    font-size: 30px;
  }
  h2,
  .h2 {
    font-size: 28px;
  }
  h6,
  .h6 {
    font-size: 16px;
  }
  p {
    font-size: 15px;
    line-height: 1.9;
  }
  .atmosphere-book-now {
    margin-top: 20px;
    width: calc(100% - 40px);
    padding: 20px;
    .discount-wrap {
      .dropdown-menu {
        min-width: 100%;
      }
    }
  }
  .tab-cover {
    height: 220px;
    border-radius: 10px;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
  .atmosphere-discover {
    padding-top: 20px !important;
  }
  .section-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .atmosphere-experience-await {
    .paragraph {
      p {
        font-size: 14.5px;
      }
    }
  }
  .btn.theme-btn {
    padding: 12px 25px;
  }
  .hotel-heading-wrap {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    .heading-space {
      width: 100%;
    }
    .view-all {
      margin: 10px auto 0;
    }
  }
  .heading-space {
    line-height: 1.4;
  }
  .dashed-heading {
    h2,
    h3 {
      &::before {
        content: none;
      }
    }
  }
  .gallery-card {
    .gallery-text-box {
      padding: 20px 18px;
      width: calc(100% - 40px);
      max-width: 100%;
      bottom: -60px;
    }
  }
  .gallery-slides {
    .slick-list {
      padding-bottom: 85px;
    }
  }
  .special-offers-slides {
    .gallery-text-box {
      h4 {
        font-size: 32px;
      }
    }
  }
  .logo-wrapper {
    background-size: 135px;
  }
  footer {
    .footer-newsletter {
      h6 {
        margin-bottom: 20px !important;
      }
      form {
        margin-left: 0;
        max-width: initial;
      }
    }
    .mein-footer {
      .footer-col,
      .footer-logo {
        border: 0;
        address {
          margin: auto;
        }
      }
      .location {
        width: 100%;
        text-align: center;
      }
    }
    .footer-logo {
      margin-bottom: 15px;
    }
    .location {
      .phone-footer {
        text-align: center !important;
      }
    }
  }
  .location {
    justify-content: center;
    .phone-footer {
      width: auto;
    }
  }
  .page-inner {
    padding: 30px 0;
  }
  .page-title {
    h2 {
      letter-spacing: 0;
    }
  }
  .dashed-heading {
    h2 {
      &::before {
        bottom: 16px;
      }
      span {
        padding-right: 15px;
      }
    }
  }
  .right-feature-text-col {
    max-width: initial;
    position: relative !important;
    width: calc(100% - 20px);
    margin: -50px auto 0;
  }
  .feature-card {
    &:hover,
    &:focus {
      .sub-card-title {
        height: auto;
        padding-bottom: 15px;
      }
    }
  }
  .sub-card-title {
    height: auto;
    padding: 35px 15px;
  }
  .atmosphere-hero-video {
    height: 500px;
    video {
      object-fit: cover;
      height: 100%;
    }
    .scroll-down {
      bottom: 40px;
    }
  }
  .book-now-modal {
    .modal-header {
      padding-bottom: 0;
    }
  }
  .modal-title {
    letter-spacing: 0;
    font-size: 24px;
  }
  .sub-card {
    bottom: -215px;
    .sub-card-title {
      height: 54px;
    }
  }
  .discover-left,
  .discover-right {
    .discover-feature-icon {
      width: 60px;
      height: 60px;
    }
    .discover-card-body {
      padding: 15px;
      p {
        margin: 0;
      }
    }
    .download-brochure {
      height: 220px;
    }
  }
  .special-offers-slides {
    .slick-arrow {
      &.slick-prev {
        left: 10px;
        right: initial;
      }
      &.slick-next {
        right: 10px;
        left: initial;
      }
    }
  }
  .brands-slide,
  .gallery-slides,
  .special-offers {
    .slick-slide {
      padding: 0 10px;
    }
    .slick-prev,
    .slick-next {
      z-index: 2;
      background-color: rgba(0, 0, 0, 0.6) !important;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      background-repeat: no-repeat !important;
      background-position: center !important;
      background-size: 8px !important;
      opacity: 1;
      &::before {
        content: none;
      }
    }
    .slick-prev {
      left: 0;
      background-image: url("../images/white-prev.svg") !important;
    }
    .slick-next {
      right: 0;
      background-image: url("../images/white-next.svg") !important;
    }
  }
  .environmental-tourism {
    padding-left: 10px;
    padding-right: 10px;
    .environmental-tourism-card {
      padding: 50px;
    }
  }
  .sustainability-cards {
    .feature-left-image {
      min-height: initial;
      img {
        height: auto;
      }
    }
    .right-feature-text-col {
      .right-feature-wrap {
        padding: 30px 25px;
      }
      .feature-text-para {
        margin: 15px 0 0;
      }
    }
  }
  .btn.theme-btn-outline {
    padding: 14px 25px;
    font-size: 16px;
    max-width: 280px;
    width: 100%;
  }
  .all-offers-list {
    .tab-top-header {
      &:nth-child(2n) {
        .col-md-9 {
          margin-left: 0;
        }
      }
      &:nth-child(odd) {
        padding: 40px 0;
      }
      &:last-child {
        padding-bottom: 40px;
      }
    }
  }
  .offers-tabs {
    .offer-info-card {
      margin-top: -50px !important;
      width: calc(100% - 20px);
    }
  }
  .press-releases-cards {
    .card-body {
      margin: -85px 15px 0;
      width: calc(100% - 30px);
    }
  }
  .thumb-articles {
    .thumb-press-card {
      margin-bottom: 20px;
    }
  }
  .sub-card-para {
    p {
      min-height: 145px;
    }
  }
  .award-card {
    .award-by {
      margin: 20px 0 0;
      span {
        max-width: initial;
        min-height: initial;
      }
    }
  }
  .media-center-tabs {
    form {
      .select-form {
        max-width: 100%;
        margin: 5px 0;
      }
    }
  }
  .collateral-card {
    padding: 15px;
    .collateral-text {
      width: calc(100% - 115px);
      h5 {
        font-size: 18px;
      }
      p {
        font-size: 14px;
      }
    }
    .collateral-docs-type {
      width: 70px;
      height: 70px;
    }
  }
  .page-inner {
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item {
        top: 1px;
        position: relative;
      }
    }
  }
  .sustainability-cards {
    .feature-left-image {
      height: auto;
    }
  }
  .green-globe-icon {
    top: initial;
    bottom: -25px;
    img {
      max-width: 70px;
    }
  }
  .our-values:not(.sustainability-cards) {
    .feature-left-image {
      height: auto;
    }
  }
  .mob-slide-image {
    height: 420px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .media-center-tabs {
    .tab-name {
      padding: 0 0;
    }
    .rts___tab {
      min-width: 195px;
    }
  }
  .special-offers {
    .gallery-card {
      .card-image {
        height: 380px;
      }
    }
  }
  .offers-tabs {
    .offer-img {
      height: 320px;
    }
  }
  .sustainability {
    .sustain-card {
      p {
        min-height: 120px;
      }
    }
  }
  .atmosphere-gallery {
    .slick-slide > div {
      padding-bottom: 85px;
    }
    .gallery-card {
      .card-image {
        height: auto;
      }
    }
  }
  .special-offers-slides {
    .slick-arrow {
      bottom: 50%;
    }
  }
  .atmosphere-cookies {
    .CookieConsent {
      padding: 15px;
      > div {
        flex-wrap: wrap;
      }
    }
    .cookies-text {
      padding-right: 0;
      width: 100%;
      text-align: center;
    }
    .cookies-actions {
      margin-top: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .gallery-card {
    .gallery-text-box {
      p {
        min-height: 75px;
      }
    }
  }
  .resorts-view-all {
    .resort-item {
      padding: 10px;
      min-width: 100%;
    }
  }
  .sub-brands-tabs {
    padding-left: 10px;
    padding-right: 10px;
  }
  .gallery-slides,
  .special-offers-slides {
    .slick-list {
      padding-bottom: 35px;
    }
  }
  .award-by {
    min-height: initial;
  }
  .award-year-title {
    max-width: 185px;
  }
  .award-text {
    padding: 0;
  }
  .book-now-modal {
    .modal-content {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  .sub-brands-tabs {
    .gradnt-brdr {
      height: 55px;
    }
  }
  .atmosphere-book-now {
    .parent {
      &.highlight {
        height: 670px;
      }
    }
  }
  .atmosphere-book-now {
    .datepicker-open ~ .daterangepicker {
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
    }
  }
}
@media (max-width: 575px) {
  h1,
  .h1 {
    font-size: 27px;
  }
  .atmosphere-gallery {
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  footer {
    .bottom-footer {
      .footer-social,
      .atmosphere-core-logo {
        width: 100%;
        text-align: center;
        margin: 12px 0;
        max-width: 100%;
      }
    }
  }
  header {
    padding-left: 5px;
    padding-right: 5px;
    .device-logo {
      img {
        max-width: 130px;
      }
    }
    .mobile-book-now {
      margin-top: -5px;
    }
    &.sticky {
      .navbar-toggler {
        top: -38px;
      }
      .mobile-book-now {
        margin-top: 0;
      }
    }
  }
  .discover-left,
  .discover-right {
    .discover-card {
      flex-wrap: wrap;
      .discover-card-image,
      .discover-card-body {
        width: 100%;
        min-height: initial;
      }
      .discover-card-body {
        padding: 35px 20px;
        &::before {
          transform: rotate(90deg) !important;
          -o-transform: rotate(90deg) !important;
          -ms-transform: rotate(90deg) !important;
          -moz-transform: rotate(90deg) !important;
          -webkit-transform: rotate(90deg) !important;
          top: 0;
          right: 0 !important;
          left: 0 !important;
          margin: auto;
        }
      }
      &:nth-child(1) {
        .discover-card-body {
          border-radius: 0;
        }
      }
    }
  }
  .sub-card {
    .sub-card-title {
      min-height: 100px;
    }
  }
  .tab-text-view {
    padding: 15px 10px;
  }
  .special-offers-slides {
    .gallery-text-box {
      margin: -30px auto 0 !important;
    }
  }
  .environmental-tourism {
    .environmental-tourism-card {
      padding: 25px;
    }
  }
  .hotels-resorts {
    .hotel-resort-mobile-slide {
      h1,
      .h1 {
        font-size: 38px;
        margin-bottom: 10px;
      }
      span {
        font-size: 17px;
      }
      small {
        font-size: 15px;
      }
    }
  }
  .page-title h2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .mob-slide-image {
    height: 350px;
  }
  .special-offers {
    .gallery-card {
      .card-image {
        height: 300px;
      }
    }
  }
  .sustainability {
    .sustain-text-box {
      h5 {
        height: auto;
      }
    }
    .sustain-card {
      p {
        min-height: initial;
      }
    }
  }
  .discover-card {
    .discover-card-image {
      img {
        height: auto;
      }
    }
  }
  .discover-left,
  .discover-right {
    .discover-title {
      min-height: inherit;
    }
    .discover-text {
      p {
        min-height: initial;
      }
    }
  }
  .tab-text-view {
    width: 100%;
  }
  .resort-tab-slides {
    overflow: hidden;
    .slick-arrow {
      &.slick-prev {
        left: 0;
      }
      &.slick-next {
        right: 0;
      }
    }
  }
}
@media (max-width: 420px) {
  .sub-card {
    bottom: -250px;
  }
  .hotels-resorts {
    .hotel-resort-mobile-slide {
      h1,
      .h1 {
        font-size: 32px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .sub-card-para p {
    min-height: 175px;
  }
  .page-not-found {
    .error-text {
      h2 {
        font-size: 40px;
      }
    }
  }
}
@media (max-width: 300px) {
  header {
    .device-logo {
      img {
        max-width: 115px;
      }
    }
    .mobile-book-now {
      margin-right: 41px;
      .book-btn {
        padding: 7px 6px !important;
        font-size: 11px;
      }
    }
  }
  .navbar-toggler {
    padding: 3px 4px;
    top: -34px;
    span {
      background-size: 15px;
      width: 25px;
      height: 25px;
    }
  }
  footer {
    .footer-newsletter {
      h6 {
        white-space: initial;
        text-align: center;
      }
    }
  }
  .value-card-text {
    p {
      padding: 15px 12px;
    }
    .value-card-title {
      margin: 15px 0;
    }
  }
  .value-card-img {
    height: 470px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .sustainability-cards {
    .right-feature-text-col {
      .top-head-text {
        h4 {
          letter-spacing: 0;
          font-size: 24px;
        }
      }
    }
  }
  .page-not-found {
    padding: 110px 0;
  }
}
@media (max-width: 991px) {
  .getting-ready {
    padding: 0 20px;
  }
  .project-logo img {
    max-width: 200px;
  }
  .toc-getting-ready .project-logo img {
    max-width: 120px;
  }
  .getting-ready-inner {
    max-width: 550px;
    margin: auto;
    padding: 35px 50px 85px;
  }
  .getting-ready-content {
    margin-top: 80px;
  }
  .getting-ready-content h1 {
    margin-bottom: 0;
    font-size: 35px;
  }
  .getting-ready-content p {
    font-size: 16px;
  }
  .getting-ready-content .book-now-btn {
    margin-top: 20px;
  }
  .footer-info {
    padding: 0 50px;
  }
}
@media (max-width: 767px) {
  .getting-ready-inner {
    padding-bottom: 150px;
  }
  .footer-info {
    height: auto;
    display: inline-block;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .footer-info a {
    display: block;
    margin: 10px 0;
  }
  .footer-info a:first-child {
    margin-right: 20px;
  }
  .ahr-guests-save-btn {
    padding: 12px 8px 8px;
  }
  .guestmodal {
    &.show {
      .modal-dialog {
        transform: translateY(0px) !important;
      }
    }
    &.fade {
      transition: none;
      .modal-dialog {
        transform: translateY(300px);
        transition: transform 700ms;
      }
    }
  }
}
@media (max-width: 575px) {
  .getting-ready {
    padding: 0 15px;
  }
  .getting-ready-inner {
    max-width: 550px;
    margin: auto;
    padding: 25px 20px;
  }
  .getting-ready-content {
    margin-top: 50px;
  }
  .getting-ready-content h1 {
    font-size: 28px;
  }
  .getting-ready-content p {
    font-size: 16px;
  }
  .footer-info {
    padding: 12px 20px;
  }
  .atmosphere-book-now {
    .daterangepicker {
      position: absolute !important;
      top: auto !important;
      left: auto !important;
      border: 0 !important;
      margin-top: 0 !important;
      &.show-calendar {
        border: 1px solid #ddd !important;
        margin-top: 7px !important;
        &.opensright {
          &::before,
          &::after {
            display: flex !important;
          }
        }
      }
      &.opensright {
        &::before,
        &::after {
          display: none !important;
        }
      }
    }
  }
}
